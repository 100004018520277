/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _, { isArray, size } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactS3Client from "react-aws-s3-typescript";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import mobileSVG from "../../../assets/media/icons/other_icons/mobile.svg";
import pin from "../../../assets/media/icons/other_icons/pin.svg";
import Process from "../../../assets/media/icons/other_icons/process.svg";
import Calendar from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutline from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import remove from "../../../assets/media/icons/standard_icons/cross.svg";
import Geo from "../../../assets/media/icons/standard_icons/geo_icon.svg";

import { LayoutContext } from "components/core/LayoutProvider";
import moment from "moment";
import AdditionalTemplate from "pages/Sales/component/AdditionalTemplate";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import {
  MobileInsturction,
  checkingValidUrlForBoth,
  getExtension,
  isValidUrl,
} from "../../../helpers/AssetHelpers";
import { showAlert } from "../../../helpers/ShowAlert";
import { PDFFailedLoad, S3_CONFIG } from "../../../helpers/const";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { AddInventoryContext } from "../core/AddInventoryProvider";
import {
  TicketError,
  copy,
  generateUniqueId,
  visibilityMeterCalculationForKeyBoard,
} from "../core/_functions";
import MobileUrlLink from "./MobileUrlLink";
import UploadFileInstruction from "./UploadFileInstruction";
import PreUpload from "./eticket_component/PreUpload";

window.Buffer = window.Buffer || require("buffer").Buffer;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

//Styled Component for Styling the Assigned List
const List = styled.li`
  background-color: ${(props) =>
    // @ts-ignore
    props?.checkedState || props?.assigned ? "rgba(0, 229, 0, 0.1)" : "white"};
`;

const ETicketUpload = ({ list, setList, instructionLetterTemplates }: any) => {
  const globalLayout = useContext(LayoutContext);
  const [files, setFiles] = useState<any>([]);

  const [pdfView, setPdfView] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<any>();
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [ticketFiles, setTicketFiles] = useState<any>([]);
  const [checkedState, setCheckedState] = useState(false);
  const [mobileLinkResponse, setMobileLinkResponse] = useState<any>();
  const layout = useContext(AddInventoryContext);
  const events = list;
  const isOpen = layout.eTicketDataField?.isOpen;
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });

  const initialTemplateState = {
    templateView: false,
    data: undefined,
    inputs: {},
  };

  const [templateState, setTemplateState] = useState<any>(initialTemplateState);

  const s3 = new ReactS3Client(S3_CONFIG);
  // @ts-ignore
  let customS3 = s3?.config;

  const eventRef = useRef<any>();

  // Assuming events is an array of objects with any structure
  const uniqueTicketTypes = useMemo(() => {
    return _.map(
      events
        ?.flatMap((event: any) =>
          event?.eventRecords?.map(
            (record: any) => record?.selected && record?.ticketType
          )
        )
        .filter(
          (value: any, index: number, self: any[]) =>
            value && self.indexOf(value) === index
        ),
      "id"
    );
  }, [events]);

  //GET COUNT OF UPLOADED LISTING

  const getUploadedListing = () => {
    let count = 0;
    let totalListing = 0;

    list?.forEach((event: any) => {
      event?.eventRecords?.forEach((eventRecord: any) => {
        if (
          eventRecord?.selected &&
          ["eticket", "pdf", "mobile-qr", "mobile-link"].includes(
            eventRecord?.ticketType?.id
          )
        ) {
          totalListing++;

          const ticketFiles = eventRecord?.ticketFiles || {};
          const nonEmptyTicketFiles = Object.values(ticketFiles).filter(
            (obj: any) => JSON.stringify(obj) !== "{}"
          );
          const mobileLinkUploaded = nonEmptyTicketFiles?.every((obj: any) =>
            eventRecord?.pkpass
              ? obj?.path && obj?.isValid && obj?.mobileLink
              : obj?.isValid && obj?.mobileLink
          );

          const nonEmptyFilesCount = nonEmptyTicketFiles?.length;
          const requiredQuantity = eventRecord?.quantity || 0;

          if (
            eventRecord?.ticketType?.id === "mobile-link" &&
            mobileLinkUploaded &&
            nonEmptyFilesCount >= requiredQuantity &&
            nonEmptyFilesCount > 0
          ) {
            count++;
          } else if (
            eventRecord?.ticketType?.id !== "mobile-link" &&
            nonEmptyFilesCount >= requiredQuantity &&
            nonEmptyFilesCount > 0
          ) {
            count++;
          }
        }
      });
    });

    return [count, totalListing];
  };

  function confirmButtonDisabled(flag: any) {
    let mainFlag = true;

    if (!flag) {
      list?.forEach((event: any, eventIndex: number) => {
        event?.["eventRecords"]?.map(
          (eventRecord: any, eventRecordIndex: any) => {
            if (
              eventRecord?.template &&
              eventRecord?.template?.id &&
              eventRecord?.template?.id !== "0" &&
              eventRecord?.template?.value !== 0
            ) {
              const allKeysHaveValues: any = eventRecord?.templateKeys.every(
                (key: number) => {
                  return (
                    eventRecord?.inputs?.[key] !== "" &&
                    eventRecord?.inputs?.[key] !== undefined &&
                    eventRecord?.inputs?.[key] !== null
                  );
                }
              );

              mainFlag = !allKeysHaveValues;
            } else {
              mainFlag = false;
            }
          }
        );
      });
    }
    return mainFlag;
  }

  // All Functions to handle the PDF's
  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: 5244899, // 5MB in bytes,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      }
      setFiles([
        ...files,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueId(),
          })
        ),
      ]);
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "vnd.apple.pkpass": [".pkpass", "pkpass"],
    },
  });

  //Function to Handle File Upload from Right side Component
  var evnIndex: any;
  var eveRecordIndex: any;
  const handleRightUpload = (eventIndex: any, eventRecordIndex: any) => {
    evnIndex = eventIndex;
    eveRecordIndex = eventRecordIndex;
    rightSideUpload.open();
  };

  const rightSideUpload = useDropzone({
    maxFiles: 1,
    maxSize: 5244899, // 5MB in bytes,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) => {
              return error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : error.code === "too-many-files"
                ? "Please select only one file at time"
                : "Invalid File Type";
            })
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      }

      setList((prev: any) => {
        return prev.map((event: any, inEventIndex: any) => {
          if (inEventIndex === evnIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, inEventRecordIndex: any) => {
                  Object.assign(eventRecord, {
                    id: generateUniqueId(),
                  });
                  if (inEventRecordIndex === eveRecordIndex) {
                    let ticket = {
                      ...eventRecord,
                      ...(eventRecord?.ticketFiles
                        ? {
                            ticketFiles: [
                              ...eventRecord?.ticketFiles,
                              ...acceptedFiles?.map((file) =>
                                Object.assign(file, {
                                  preview: URL.createObjectURL(file),
                                  id: generateUniqueId(),
                                  assigned: 1,
                                })
                              ),
                            ],
                          }
                        : {
                            ticketFiles: [
                              ...acceptedFiles?.map((file) =>
                                Object.assign(file, {
                                  preview: URL.createObjectURL(file),
                                  id: generateUniqueId(),
                                  assigned: 1,
                                })
                              ),
                            ],
                          }),
                    };
                    return ticket;
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else {
            return event;
          }
        });
      });
      setFiles([
        ...files,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueId(),
            assigned: 1,
          })
        ),
      ]);
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
      "vnd.apple.pkpass": [".pkpass", "pkpass"],
    },
  });

  // Function to view PDF
  const pdfViewer = (file: any) => {
    setPageNumber(1);
    setFilePreview(file);
    setPdfView(true);
  };

  // Function for Deleting PDF
  const deletePdf = (file: any, index: number) => {
    setFiles((currentPDF: any) =>
      currentPDF.filter(
        (item: any, filterIndex: number) => index !== filterIndex
      )
    );
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  // Drag n Drop Functionality
  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  var dragArea: any = document.querySelector<HTMLElement>("#drag_here");
  const onDragStart = () => {
    if (dragArea) {
      dragArea?.classList?.add("!border-green-500");
    }
    // for (var i = 0; i < dragArea.length; i++) {
    //   dragArea[i].classList?.add("!border-green-500");
    //   // dragArea[i].style.borderColor = "#6F6CFF";
    // }
  };

  const uploadMobileLinkAWS = async (
    eventIndex: any,
    eventRecordIndex: any,
    arrItemIndex: any,
    finalData: any,
    dropData: any
  ) => {
    let ticketData = finalData;

    let additionalConfig = {
      ...customS3,
      dirName: `ticket-files/mobile-links/2`,
    };

    const s3Upload = new ReactS3Client(additionalConfig);

    try {
      const data = await s3Upload.uploadFile(dropData);
      setMobileLinkResponse((current: any) =>
        current && size(current) > 0
          ? [
              ...current,
              {
                eventIndex,
                eventRecordIndex,
                arrItemIndex,
                ticketData,
                url: data?.location,
              },
            ]
          : [
              {
                eventIndex,
                eventRecordIndex,
                arrItemIndex,
                ticketData,
                url: data?.location,
              },
            ]
      );
    } catch (error) {
      console.error("Error uploading file to AWS S3:", error);
    }
  };

  /**
   * Moves an item from one list to another list.
   */

  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};

    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const deletePDFFromFiles = (id: any) => {
    markAssignedZero(id);
  };

  let onDragEnd = (result: any) => {
    // for (var i = 0; i < dragArea.length; i++) {
    //   dragArea[i].classList.remove("!border-green-500");
    // }
    if (dragArea) {
      dragArea?.classList?.remove("!border-green-500");
    }
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId === "eventTicketUploadedFiles") {
        const items = reorder(files, source.index, destination.index);
        setFiles(items);
      } else {
        const items = reorder(ticketFiles, source.index, destination.index);
        setList((prev: any) => {
          return prev.map((event: any, eventIndex: number) => {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (eventRecord.ticketFiles) {
                    return {
                      ...eventRecord,
                      ticketFiles: reorder(
                        eventRecord?.ticketFiles,
                        source.index,
                        destination.index
                      ),
                    };
                  } else {
                    return {
                      ...eventRecord,
                    };
                  }
                }
              ),
            };
          });
        });
      }
    } else if (destination.droppableId === "eventTicketUploadedFiles") {
      // drag from right to left
      let sourceIndex = source.droppableId.split("-");

      let sourceFiles =
        list[sourceIndex[0]]["eventRecords"][sourceIndex[1]]?.ticketFiles;

      sourceFiles.map((file: any, index: any) => {
        if (source.index === index) {
          file.assigned = 0;
        }
      });

      let updatedSourceFiles = sourceFiles.filter(
        (file: any, index: any) => source.index !== index
      );
      setList((prev: any) => {
        return prev.map((event: any, eventIndex: number) => {
          if (parseInt(sourceIndex[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (parseInt(sourceIndex[1]) === evnetRecordIndex) {
                    return {
                      ...eventRecord,
                      ticketFiles: updatedSourceFiles,
                    };
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else {
            return event;
          }
        });
      });
    } else if (source.droppableId === "eventTicketUploadedFiles") {
      let destinationIndexs = destination.droppableId.split("-");
      let destinationFiles: any;
      // let destinationFiles =
      //   list?.[destinationIndexs[0]]?.["eventRecords"][destinationIndexs[1]]
      //     ?.ticketFiles?.[destinationIndexs[2]];

      const eventRecord =
        list?.[destinationIndexs[0]]?.eventRecords?.[destinationIndexs[1]];

      if (destinationIndexs[2] === "additionalFile") {
        eventRecord.additionalFile ||= [{}];
        destinationFiles = eventRecord.additionalFile?.[0];
      } else {
        destinationFiles = eventRecord?.ticketFiles?.[destinationIndexs[2]];
      }

      const move_result = move(
        files,
        isArray(destinationFiles) ? destinationFiles : [],
        source,
        destination
      );

      const markAssigned = () => {
        files.map((file: any, index: any) => {
          if (source.index === index) {
            file.assigned = 1;
          }
        });
      };

      // LEFT TO RIGHT
      setList((prev: any) => {
        return prev.map((event: any, eventIndex: number) => {
          if (parseInt(destinationIndexs[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (
                    parseInt(destinationIndexs[1]) === evnetRecordIndex &&
                    destinationIndexs[2] === "additionalFile"
                  ) {
                    // if (
                    //   move_result[destination.droppableId]?.[0]?.type ===
                    //   "application/pdf"
                    // ) {
                    markAssigned();
                    if (
                      destinationIndexs[2] === "additionalFile" &&
                      eventRecord?.addtionalFile
                    ) {
                      deletePDFFromFiles(eventRecord?.addtionalFile?.id);
                    }
                    return {
                      ...eventRecord,
                      additionalFile: move_result[destination.droppableId]?.[0],
                    };
                    // }
                  } else if (
                    parseInt(destinationIndexs[1]) === evnetRecordIndex
                  ) {
                    return {
                      ...eventRecord,
                      ticketFiles: eventRecord?.ticketFiles?.map(
                        (arrItem: any, arrIndex: number) => {
                          // MOBILE LINK
                          if (
                            parseInt(destinationIndexs[2]) === arrIndex &&
                            eventRecord?.ticketType?.id === "mobile-link" &&
                            getExtension(
                              move_result[destination.droppableId]?.[0]?.path
                            ) === "pkpass"
                          ) {
                            arrItem && deletePDFFromFiles(arrItem?.id);
                            markAssigned();
                            let final = {
                              ...move_result[destination.droppableId]?.[0],
                            };
                            uploadMobileLinkAWS(
                              eventIndex,
                              evnetRecordIndex,
                              arrItem,
                              final,
                              move_result[destination.droppableId]?.[0]
                            );
                            return final;
                          }
                          // MOBILE / ETICKET
                          else if (
                            (eventRecord?.ticketType?.id === "pdf" ||
                              eventRecord?.ticketType?.id === "eticket" ||
                              eventRecord?.ticketType?.id === "mobile-qr") &&
                            parseInt(destinationIndexs[2]) === arrIndex &&
                            move_result[destination.droppableId]?.[0]?.type ===
                              "application/pdf"
                          ) {
                            // FILE REPLACE
                            arrItem && deletePDFFromFiles(arrItem?.id);
                            markAssigned();
                            return move_result[destination.droppableId]?.[0];
                          } else {
                            return arrItem;
                          }
                        }
                      ),
                      // ticketFiles: move_result[destination.droppableId],
                    };
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else {
            return event;
          }
        });
      });
    } else {
      let sourceIndexs = source.droppableId.split("-");
      let sourceFiles =
        list?.[sourceIndexs[0]]?.["eventRecords"][sourceIndexs[1]]?.ticketFiles;

      let destinationIndexs = destination.droppableId.split("-");
      let destinationFiles =
        list?.[destinationIndexs[0]]?.["eventRecords"][destinationIndexs[1]]
          ?.ticketFiles;

      const move_result = move(
        isArray(sourceFiles) ? sourceFiles : [],
        isArray(destinationFiles) ? destinationFiles : [],
        source,
        destination
      );

      const updatedEvents = list.map((event: any, eventIndex: number) => {
        if (parseInt(sourceIndexs[0]) === eventIndex) {
          return {
            ...event,
            eventRecords: event["eventRecords"].map(
              (eventRecord: any, evnetRecordIndex: number) => {
                if (parseInt(sourceIndexs[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[source.droppableId],
                  };
                } else if (
                  parseInt(destinationIndexs[1]) === evnetRecordIndex
                ) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[destination.droppableId],
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        } else if (parseInt(destinationIndexs[0]) === eventIndex) {
          return {
            ...event,
            eventRecords: event["eventRecords"].map(
              (eventRecord: any, evnetRecordIndex: number) => {
                if (parseInt(destinationIndexs[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[destination.droppableId],
                  };
                }
              }
            ),
          };
        } else if (parseInt(destinationIndexs[0]) === eventIndex) {
          return {
            ...event,
            eventRecords: event["eventRecords"].map(
              (eventRecord: any, evnetRecordIndex: number) => {
                if (parseInt(destinationIndexs[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[destination.droppableId],
                  };
                } else if (parseInt(sourceIndexs[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[source.droppableId],
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        } else {
          return event;
        }
      });

      setList(updatedEvents);
    }

    // if (dragArea) {
    //   dragArea.style.borderColor = "inherit";
    //   dragArea?.classList.remove("!border-green-500");
    // }
  };

  const markAssignedZero = (id: any) => {
    files.map((file: any, index: any) => {
      if (file?.id === id) {
        file.assigned = 0;
      }
    });
  };
  // console.log(list?.[0]?.eventRecords?.[1]?.ticketFiles);
  // Function for Deleting PDF's from Right Side Component
  const deletePdfRightSide = (
    file: any,
    index: any,
    eventRecordIndex: number,
    eventIndex: number
  ) => {
    setList((prev: any) => {
      return list.map((event: any, inEventIndex: number) => {
        if (inEventIndex === eventIndex) {
          return {
            ...event,
            eventRecords: event.eventRecords.map(
              (eventRecord: any, inEventRecordIndex: any) => {
                if (inEventRecordIndex === eventRecordIndex) {
                  if (index === "additionalFile") {
                    delete eventRecord?.additionalFile;
                    return eventRecord;
                  }
                  return {
                    ...eventRecord,
                    // ticketFiles: eventRecord.ticketFiles?.filter(
                    //   (ticketFile: any, ticketFileIndex: any) => {
                    //     if (ticketFileIndex !== index) {
                    //       return ticketFiles;
                    //     }
                    //   }
                    // ),
                    ticketFiles:
                      eventRecord?.quantity < eventRecord.ticketFiles?.length
                        ? eventRecord.ticketFiles?.filter(
                            (ticketFile: any, ticketFileIndex: any) => {
                              if (ticketFileIndex !== index) {
                                return ticketFiles;
                              }
                            }
                          )
                        : eventRecord.ticketFiles?.map(
                            (ticketFile: any, ticketFileIndex: any) => {
                              if (ticketFileIndex === index) {
                                return {};
                              } else {
                                return ticketFile;
                              }
                            }
                          ),
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        } else {
          return event;
        }
      });
    });
    setFiles((prev: any) => {
      return prev.map((inFile: any) => {
        if (inFile.id === file.id) {
          return {
            ...inFile,
            type: file?.type,
            assigned: 0,
          };
        } else {
          return inFile;
        }
      });
    });
  };

  useEffect(() => {
    if (size(mobileLinkResponse) > 0) {
      setList((current: any) => {
        return current?.map((event: any, eventIndex: number) => {
          let mobileEventIndex = mobileLinkResponse?.find(
            (item: any) => item?.eventIndex === eventIndex
          );

          if (eventIndex === mobileEventIndex?.eventIndex) {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map(
                (record: any, recordIndex: number) => {
                  let eventRecordIndex = mobileLinkResponse?.find(
                    (item: any) => item?.eventRecordIndex === recordIndex
                  );

                  if (eventRecordIndex) {
                    return {
                      ...record,
                      ticketFiles: record?.ticketFiles?.map(
                        (item: any, itemIndex: number) => {
                          let ticketID = mobileLinkResponse?.find(
                            (ticketItem: any) =>
                              ticketItem?.ticketData?.id === item?.id
                          );
                          if (ticketID?.ticketData?.id) {
                            setMobileLinkResponse((current: any) => {
                              return current?.filter(
                                (mobileResponse: any) =>
                                  mobileResponse?.ticketData?.id !== item?.id
                              );
                            });
                            return {
                              ...item,
                              mobileLink: ticketID?.url,
                              isValid: true,
                            };
                          } else return item;
                        }
                      ),
                    };
                  } else return record;
                }
              ),
            };
          } else return event;
        });
      });
    }
  }, [mobileLinkResponse]);

  // Handle Event Cancel/Confirm
  const handleEventCancel = () => {
    setFiles([]);
    layout.setETicketDataField({
      btnEvent: {},
      isOpen: false,
    });
    setList((current: any) => {
      return layout.tempEvent?.map((event: any) => {
        return {
          ...event,
          eventRecords: event?.eventRecords?.map((eventRecord: any) => {
            if (
              eventRecord?.ticketFiles?.length > 0 &&
              eventRecord?.ticketType?.id === "mobile-link"
            ) {
              eventRecord.ticketFiles = eventRecord.ticketFiles.map(
                (obj: any) => {
                  if (obj?.mobileLink && !obj?.isValid) {
                    return {};
                  }
                  return obj;
                }
              );
            }
            return {
              ...eventRecord,
              selected: false,
            };
          }),
        };
      });
    });
  };

  const handleEventConfirm = () => {
    setList((current: any) => {
      return current?.map((event: any) => {
        return {
          ...event,
          eventRecords: event?.eventRecords?.map(
            (eventRecord: any, index: any) => {
              let visibility: any = visibilityMeterCalculationForKeyBoard(
                event,
                { ...eventRecord, ticketsInHands: true }
              );

              setFiles([]);
              layout.setETicketDataField({
                btnEvent: {},
                isOpen: false,
              });

              const shouldClearQuantityAvailable =
                (eventRecord.errors?.category !== "" &&
                  eventRecord.errors?.category !== undefined) ||
                (eventRecord.errors?.face_value !== "" &&
                  eventRecord.errors?.face_value !== undefined) ||
                (eventRecord.errors?.sell_price !== "" &&
                  eventRecord.errors?.sell_price !== undefined);

              return {
                ...eventRecord,
                ...(shouldClearQuantityAvailable
                  ? {
                      errors: {
                        ...eventRecord.errors,
                        quantity_available: "",
                      },
                    }
                  : {
                      errors: {
                        ...eventRecord.errors,
                      },
                    }),
                // : { errors: {} }),
                selected: false,
                oldVisibility:
                  eventRecord?.visibility === visibility
                    ? eventRecord?.oldVisibility
                    : eventRecord?.visibility,
                visibility: visibility,
                ticketsInHands: true,
              };
            }
          ),
        };
      });
    });
  };

  useEffect(() => {
    var popup = document.querySelector("#ppeRightCanvas");
    var popup_contain = popup?.classList.contains("vis");
    var popup_contain_hid = popup?.classList.remove("vis");
    var body = document.querySelector("body");
    if (popup_contain) {
      body?.classList.add("overflow-hidden");
    }
    return () => {
      body?.classList?.remove("overflow-hidden");
    };
  }, []);

  let isAllMobileLinkSelected = list?.filter((event: any) => {
    return layout.eTicketDataField?.btnEvent.includes(event?.id);
  });

  const allSelectedAreMobileLink = false;

  // isAllMobileLinkSelected?.every((item: any) =>
  //   item.eventRecords
  //     .filter((record: any) => record.selected === true)
  //     ?.every((item: any) => item?.ticketType?.id === "mobile-link")
  // );

  const [formikValues, setFormikValues] = useState({
    mobileLinksRecords: [{ url: "" }],
  });

  const mobileLinkHandleChange = (
    e: any,
    eventIndex: any,
    recordIndex: any
  ) => {
    // setList((prev: any) => {
    //   return prev?.map((inEvent: any, eIndex: any) => {
    //     if (eIndex === eventIndex) {
    //       return {
    //         ...inEvent,
    //         eventRecords: inEvent?.eventRecords?.map(
    //           (record: any, rIndex: any) => {
    //             if (rIndex === recordIndex) {
    //               console.log(record);
    //               return {
    //                 ...record,
    //                 ...(!record?.mobileLinkRecords && {
    //                   mobileLinkRecords: [
    //                     {
    //                       url: "",
    //                       // err
    //                     },
    //                   ],
    //                 }),
    //               };
    //             } else {
    //               return record;
    //             }
    //           }
    //         ),
    //       };
    //     } else {
    //       return inEvent;
    //     }
    //   });
    // });
  };

  const handlePKPASS = (e: any, eventIndex: any, recordIndex: any) => {
    let uncheckedFileIds: any[] = [];
    setList((prev: any) => {
      return prev?.map((inEvent: any, eIndex: any) => {
        if (eIndex === eventIndex) {
          return {
            ...inEvent,
            eventRecords: inEvent?.eventRecords?.map(
              (record: any, rIndex: number) => {
                if (rIndex === recordIndex) {
                  // Replace ticket files without a path with empty objects if the checkbox is checked
                  if (!e?.target?.checked) {
                    const updatedTicketFiles = record?.ticketFiles?.map(
                      (obj: any) => obj.id
                    );
                    uncheckedFileIds.push(...updatedTicketFiles);
                  }
                  return {
                    ...record,
                    pkPass: e?.target?.checked,
                    separate_links: false,
                    ticketFiles: record?.ticketFiles?.map((obj: any) => ({})),
                  };
                } else {
                  return record;
                }
              }
            ),
          };
        } else {
          return inEvent;
        }
      });
    });
    if (!e?.target?.checked && uncheckedFileIds.length > 0) {
      setFiles((prevFiles: any) =>
        prevFiles?.map((file: any, index: any) => {
          return {
            ...file,
            assigned: uncheckedFileIds.includes(file?.id) ? 0 : file?.assigned,
          };
        })
      );
    }
  };

  // HANDLE Separate links for ios/android? TOGGLE
  const handleSeparateLinks = (e: any, eventIndex: any, recordIndex: any) => {
    setList((prev: any) => {
      return prev?.map((inEvent: any, eIndex: any) => {
        if (eIndex === eventIndex) {
          return {
            ...inEvent,
            eventRecords: inEvent?.eventRecords?.map(
              (record: any, rIndex: number) => {
                if (rIndex === recordIndex) {
                  const updatedTicketFiles =
                    record?.ticketFiles?.length > 0
                      ? record?.ticketFiles.map(() => ({}))
                      : record?.ticketFiles;

                  return {
                    ...record,
                    separate_links: e?.target?.checked,
                    ticketFiles: updatedTicketFiles,
                  };
                } else {
                  return record;
                }
              }
            ),
          };
        } else {
          return inEvent;
        }
      });
    });
  };

  function getCategoryValue(record: any, mainEvent: any) {
    return record?.category?.id
      ? record?.category
      : mainEvent?.categories &&
          mainEvent?.categories.find(
            (category: any) => Number(category.id) === Number(record?.category)
          );
  }

  function getSectionValue(record: any, mainEvent: any) {
    return record?.section && record?.section?.id
      ? record?.section
      : record?.section &&
          mainEvent?.categories &&
          Array.isArray(mainEvent?.categories) &&
          mainEvent?.categories
            .find(
              (category: any) =>
                Number(category.id) === Number(record?.category)
            )
            ?.sections?.find(
              (item: any) => Number(item?.id) === Number(record?.section)
            );
  }

  const handleMobileLinkRemove = (
    eventId: any,
    recordId: any,
    ticketFileIndex: any
  ) => {
    setList((current: any) => {
      return current?.map((events: any, eventIndex: number) => {
        if (eventIndex === eventId) {
          return {
            ...events,
            eventRecords: events?.eventRecords?.map(
              (record: any, recordIndex: number) => {
                if (recordIndex === recordId) {
                  return {
                    ...record,
                    ticketFiles: record?.ticketFiles?.map(
                      (ticket: any, ticketIndex: number) => {
                        if (ticketIndex === ticketFileIndex) {
                          deletePDFFromFiles(ticket?.id);
                          delete ticket?.isValid;
                          return {};
                        } else return ticket;
                      }
                    ),
                  };
                } else return record;
              }
            ),
          };
        } else return events;
      });
    });
  };
  // MOBILE LINK HANDLE
  const handleMobileLinkChange = (
    eventId: any,
    recordId: any,
    event: any,
    isConfirm: any,
    isDelete: any,
    ticketFileIndex: any,
    ticketType: any
  ) => {
    const mobileLinkId: any = `mobile-link-${recordId}-${ticketFileIndex}`;
    const mobileLinkElement: any = document.getElementById(
      mobileLinkId
    ) as HTMLInputElement | null;

    const mobileLinkId_separate: any = `mobile-link-${recordId}-${ticketFileIndex}-separate`;
    const mobileLinkElement_separate: any = document.getElementById(
      mobileLinkId_separate
    ) as HTMLInputElement | null;

    setList((current: any) => {
      return current?.map((events: any, eventIndex: number) => {
        if (eventIndex === eventId) {
          return {
            ...events,
            eventRecords: events?.eventRecords?.map(
              (record: any, recordIndex: number) => {
                if (recordIndex === recordId) {
                  let ticketFiles = record?.ticketFiles?.map(
                    (ticket: any, ticketIndex: number) => {
                      if (ticketIndex === ticketFileIndex) {
                        if (isDelete) {
                          if (record?.separate_links) {
                            delete ticket?.isValid;
                            if (ticketType === "android") {
                              delete ticket?.isValidAndroid;
                              if (mobileLinkElement) {
                                mobileLinkElement.value = "";
                              }
                              const final = {
                                ...ticket,
                                mobileLink: {
                                  ...ticket?.mobileLink,
                                  android: "",
                                },
                              };
                              return final;
                            } else if (ticketType === "ios") {
                              delete ticket?.isValidIos;
                              if (mobileLinkElement_separate) {
                                mobileLinkElement_separate.value = "";
                              }
                              const final = {
                                ...ticket,
                                mobileLink: {
                                  ...ticket?.mobileLink,
                                  ios: "",
                                },
                              };
                              return final;
                            }
                            deletePDFFromFiles(ticket?.id);
                            if (
                              !ticket?.mobileLink?.android &&
                              !ticket?.mobileLink?.ios
                            ) {
                              return {};
                            }
                            // return ticket;
                          } else {
                            delete ticket?.isValid;
                            deletePDFFromFiles(ticket?.id);
                            if (mobileLinkElement) {
                              mobileLinkElement.value = "";
                            }
                            return {};
                          }
                        } else if (isConfirm) {
                          if (record?.separate_links) {
                            return {
                              ...ticket,
                              isValidAndroid: isValidUrl(
                                ticket?.mobileLink?.android
                              ),
                              isValidIos: isValidUrl(ticket?.mobileLink?.ios),
                              isValid: checkingValidUrlForBoth(
                                ticket?.mobileLink
                              ),
                            };
                          }
                          return {
                            ...ticket,
                            isValid: isValidUrl(ticket?.mobileLink),
                          };
                        } else {
                          delete ticket?.isValid;
                          const mobileLinkData = {
                            android:
                              ticketType === "android"
                                ? event?.target?.value
                                : ticket?.mobileLink?.android || null,
                            ios:
                              ticketType === "ios"
                                ? event?.target?.value
                                : ticket?.mobileLink?.ios || null,
                          };

                          return {
                            ...ticket,
                            mobileLink: record?.separate_links
                              ? mobileLinkData
                              : event?.target?.value,
                          };
                        }
                      } else return ticket;
                    }
                  );

                  let final = {
                    ...record,
                    ticketFiles: ticketFiles,
                    isFull: ticketFiles?.every((item: any) => item?.isValid),
                  };
                  return final;
                } else return record;
              }
            ),
          };
        } else return events;
      });
    });
  };

  function uploadTicketTooltipWithAssignTickets(
    eventIndex: any,
    eventRecordIndex: any,
    eventRecord: any,
    events: any
  ) {
    // Extract ticket type
    const ticketType = eventRecord?.ticketType?.id;

    // Determine tooltip based on ticket type
    let tooltip = "";
    if (ticketType === "mobile-qr") {
      tooltip = "Mobile Screenshot";
    } else if (ticketType === "pdf") {
      tooltip = "E-ticket";
    } else if (ticketType === "mobile-link") {
      tooltip = "Mobile Link/PKPASS";
    } else if (ticketType === "local-delivery") {
      tooltip = "Local Delivery";
    }

    // Count assigned tickets
    const assignedTicketsCount =
      events[eventIndex]?.eventRecords[eventRecordIndex]?.ticketFiles?.filter(
        (obj: any) => JSON.stringify(obj) !== "{}"
      )?.length ?? 0;

    // Total tickets
    const totalTickets = eventRecord?.quantity ?? 0;

    // Compose tooltip text
    const tooltipText = `<div class="text-center">${tooltip}<br>${assignedTicketsCount} of ${totalTickets} tickets assigned</div>`;

    return tooltipText;
  }

  // HANDLE TEMPLATE CHANGE
  const handleChangeTemplate = (
    template: any,
    eventRecordIndex: number,
    eventId: number
  ) => {
    setList((prev: any) =>
      prev?.map((events: any, eventIndex: number) => {
        if (eventIndex !== eventId) return events;

        const updatedEventRecords = events?.eventRecords?.map(
          (record: any, recordIndex: number) => {
            if (recordIndex !== eventRecordIndex) return record;

            const regex = /\[([^\]:]*):?DYNAMIC_CONTENT\]/g;
            const foundKeys = new Set();
            let match;
            while ((match = regex.exec(template?.html_content)) !== null) {
              foundKeys.add((match[1] || "Dynamic content").trim());
            }

            return {
              ...record,
              template: template,
              templateKeys: Array.from(foundKeys),
              inputs: {},
            };
          }
        );

        return {
          ...events,
          eventRecords: updatedEventRecords,
        };
      })
    );
  };

  const handleInputChanges = (
    e: any,
    eventRecordIndex: any,
    eventId: any,
    key: any
  ) => {
    setList((prev: any) =>
      prev?.map((events: any, eventIndex: number) => {
        if (eventIndex !== eventId) return events;

        const updatedEventRecords = events?.eventRecords?.map(
          (record: any, recordIndex: number) => {
            if (recordIndex !== eventRecordIndex) return record;

            let inputs = {
              ...record?.inputs,
              [key]: e?.target?.value,
            };
            setTemplateState((pre: any) => {
              return {
                ...pre,
                inputs: inputs,
              };
            });
            return {
              ...record,
              inputs: inputs,
            };
          }
        );

        return {
          ...events,
          eventRecords: updatedEventRecords,
        };
      })
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div
        className={`offcanvas show offcanvas-end upload-ticket-popup fixed bottom-0 top-[3.75rem] right-0  md:w-[calc(100%-3.75rem)] w-full  shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium bg-black/70 ${
          isOpen ? "vis" : "hid"
        } 
               `}
        tabIndex={-1}
        id="ppeRightCanvas"
        // aria-labelledby="ppeRightCanvasLabel"
      >
        <div
          className={`relative flex flex-col  h-full bg-white ml-auto ${
            allSelectedAreMobileLink
              ? "max-w-[43.125rem]"
              : "max-w-[93rem] md:w-[95%]"
          } `}
        >
          <div className="offcanvas-header flex items-center justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              {!allSelectedAreMobileLink ? (
                <>
                  {/* E-ticket: Upload{" "}
                  {layout.countSelectedEdit?.events > 1 && "to"}{" "}
                  {getUploadedListing()[1]} listing
                  {layout.countSelectedEdit?.events > 1 &&
                    ` across to ${layout.countSelectedEdit?.events} events`} */}
                  Upload tickets
                </>
              ) : (
                <>Mobile Link</>
              )}
            </h5>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content h-full group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={(data: any) => {
                handleEventCancel();
              }}
            >
              <KTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path="standard_icons/cross.svg"
                svgClassName="w-[.5625rem] h-[.5938rem]"
              />
            </button>
          </div>
          {/* PDF VIEWER */}
          <div className="offcanvas-body h-full w-full flex flex-col lg2:flex-row overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            {pdfView ? (
              <div
                className={`lg2:border-r w-full xxl:h-full lg2:w-1/2 p-4 md:p-5 flex items-center !justify-start flex-col xxl:scrollbar-thin xxl:scrollbar-thumb-violet-800 xxl:scrollbar-thumb-rounded-full`}
              >
                <div className="border rounded w-full align-middle">
                  <div className="flex w-full border-b">
                    <div className="flex items-center justify-between w-full px-4 py-2">
                      <div className="text-xxs md:text-xs">
                        {filePreview.name}
                      </div>
                      <div className="flex">
                        <button
                          className={`rounded-l bg-gray-100 hover:bg-gray-200 transition p-2 ${
                            pageNumber === 1 && "pointer-events-none"
                          }`}
                          type="button"
                          onClick={(data: any) =>
                            setPageNumber(
                              (count: any) => count !== 1 && count - 1
                            )
                          }
                        >
                          <KTSVG
                            className="flex items-center justify-center fill-violet-500 transition"
                            path="standard_icons/angle_left.svg"
                          />
                        </button>
                        <button
                          className={`rounded-r bg-gray-100 hover:bg-gray-200 transition p-2 ${
                            numPages === pageNumber && "pointer-events-none"
                          }`}
                          type="button"
                          onClick={(data: any) =>
                            setPageNumber(
                              (count: any) => count !== numPages && count + 1
                            )
                          }
                        >
                          <KTSVG
                            className="flex items-center justify-center fill-violet-500 transition"
                            path="standard_icons/angle_right.svg"
                          />
                        </button>
                      </div>
                    </div>
                    <button
                      className="flex items-center justify-center border-l w-11 group"
                      type="button"
                      onClick={(data: any) => {
                        setPdfView(false);
                      }}
                    >
                      <KTSVG
                        svgClassName="h-5 w-5"
                        className="flex items-center justify-center fill-violet-800 group-hover:fill-indigo-500 transition"
                        path="other_icons/Clear.svg"
                      />
                    </button>
                  </div>
                  <div className="uploaded-file-preview overflow-hidden">
                    <Document
                      file={filePreview.preview}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <IKTSVG
                          className="flex items-center justify-center mr-2 mt-5 fill-violet-500 transition animate-spin"
                          path={Process}
                        />
                      }
                      error={
                        filePreview?.path?.split(".").pop() === "pdf" ? (
                          PDFFailedLoad
                        ) : (
                          <img src={filePreview?.preview} />
                        )
                      }
                    >
                      <Page pageNumber={pageNumber} pageIndex={pageNumber} />
                    </Document>
                  </div>
                  <div className="ml-auto p-2 w-8 text-xs text-gray-400">
                    {pageNumber}
                  </div>
                </div>
              </div>
            ) : (
              !allSelectedAreMobileLink && (
                <div
                  className={`lg2:border-r w-full lg2:w-1/2 p-4 md:p-5 flex justify-start  flex-col ${
                    files && files.length > 0 && "!justify-start"
                  }`}
                >
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    className={`flex flex-col items-center justify-center text-center ${
                      files && files.length > 0 ? "h-20" : "h-20"
                    } w-full min-h-[5rem] border border-dashed border-violet-500/10 rounded bg-violet-300/50 text-xs`}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-2.5">Upload or drop files here</div>
                    <button
                      type="button"
                      onClick={open}
                      className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                    >
                      <KTSVG
                        className="fill-white mr-2"
                        path="other_icons/Upload.svg"
                        svgClassName="w-[.8125rem] h-[.6875rem]"
                      />
                      Upload
                    </button>
                  </div>
                  {!(files && files.length > 0) && (
                    <>
                      {(uniqueTicketTypes.includes("eticket") ||
                        uniqueTicketTypes.includes("pdf") ||
                        uniqueTicketTypes.includes("mobile-qr")) && (
                        <UploadFileInstruction
                          data={{
                            // heading: `E-ticket upload instructions`,
                            heading: `${
                              uniqueTicketTypes.includes("mobile-qr")
                                ? "Mobile Screenshot"
                                : "E-ticket"
                            } upload instructions`,
                            listing: [
                              "Use this window to upload individual tickets for each e-ticket order (PDF format)",
                              "Drag and drop each file - ensuring any designated seat matches the ticket",
                              "Confirm upload by clicking the green 'confirm' button",
                            ],
                            type: "E-Ticket",
                            extra:
                              "Making changes to uploaded tickets is limited to unpublished listings. Upon publishing the listing, tickets will be secured for delivery and sell faster on the Tixstock Network",
                          }}
                        />
                      )}

                      {uniqueTicketTypes.includes("mobile-link") && (
                        <UploadFileInstruction
                          data={{
                            heading: `Mobile Link/PKPASS upload instructions`,
                            listing: MobileInsturction,
                            type: "Mobile",
                          }}
                        />
                      )}
                    </>
                  )}
                  {files && files.length > 0 && (
                    <aside className="w-full mt-5">
                      <div className="flex flex-wrap gap-1 mb-5">
                        <h4 className="text-sm flex-auto">
                          {files.length} file uploaded{" "}
                          {/* Tickets uploaded,{" "} */}
                          {
                            files.filter((file: any) => file.assigned).length
                          }{" "}
                          assigned
                        </h4>
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs">Show assigned</span>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                              checked={checkedState}
                              onChange={() => setCheckedState(!checkedState)}
                            />
                            <div className="w-7 h-3 bg-gray-200 peer-checked:bg-green-600/40 rounded-full transition-all peer peer-checked:after:translate-x-full peer-checked:after:bg-green-600 after:content-[''] after:absolute after:-top-0.5 after:-left-0.5 after:bg-gray-400 after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100"></div>
                          </label>
                        </div>
                      </div>
                      {/* LEFT INNER DRAG  */}
                      <Droppable
                        droppableId="eventTicketUploadedFiles"
                        key="eventTicketUploadedFiles"
                      >
                        {(provided: any, snapshot: any) => (
                          <ul ref={provided.innerRef}>
                            {files?.map((file: any, index: number) => {
                              return (
                                (file?.assigned !== 1 || checkedState) && (
                                  <Draggable
                                    key={index}
                                    // draggableId={file.path}
                                    draggableId={`${file.path}${index}`}
                                    index={index}
                                    isDragDisabled={
                                      file.assigned ? true : false
                                    }
                                  >
                                    {(provided: any, snapshot: any) => {
                                      if (snapshot?.isDragging) {
                                        eventRef.current.classList.add(
                                          "max-lg2:mt-[30px]"
                                        );
                                      } else
                                        eventRef.current.classList.remove(
                                          "max-lg2:mt-[30px]"
                                        );

                                      return (
                                        // @ts-ignore
                                        <List checkedState={checkedState}>
                                          <span
                                            key={index}
                                            className={`${
                                              file.assigned === 1
                                                ? "flex   hover:bg-green-600/10 border border-green-600 hover:border-green-600 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                                : "flex bg-white hover:bg-green-600/10 border hover:border-green-600 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            isdragging={snapshot.isdragging}
                                          >
                                            <span
                                              className="text-xs flex-auto truncate"
                                              title={file.path}
                                            >
                                              {" "}
                                              {file.path}{" "}
                                            </span>
                                            <span
                                              key={index}
                                              className="flex s-center gap-x-2"
                                            >
                                              {file?.type && (
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    pdfViewer(file)
                                                  }
                                                  id={`View-${index}`}
                                                >
                                                  <Tooltip
                                                    anchorId={`View-${index}`}
                                                    content="View"
                                                    place="top"
                                                    variant="light"
                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                  />
                                                  <KTSVG
                                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                                    path="other_icons/Eye.svg"
                                                    svgClassName="w-4 h-[.6875rem]"
                                                  />
                                                </button>
                                              )}

                                              <button
                                                type="button"
                                                onClick={() =>
                                                  deletePdf(file, index)
                                                }
                                                id={`delete-${index}`}
                                              >
                                                <KTSVG
                                                  className="fill-violet-500 hover:fill-indigo-500 transition"
                                                  path="other_icons/Delete.svg"
                                                  svgClassName="w-2.5 h-[.8125rem]"
                                                />
                                              </button>
                                              <Tooltip
                                                anchorId={`delete-${index}`}
                                                content="Delete"
                                                place="top"
                                                variant="light"
                                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                              />
                                            </span>
                                          </span>
                                        </List>
                                      );
                                    }}
                                  </Draggable>
                                )
                              );
                            })}
                          </ul>
                        )}
                      </Droppable>
                    </aside>
                  )}
                </div>
              )
            )}

            {templateState?.templateView && (
              <AdditionalTemplate
                template={templateState?.data}
                templateInputes={templateState?.inputs}
                handleOnClose={(data: any) => {
                  setTemplateState(initialTemplateState);
                }}
              />
            )}

            <div
              className={`w-full  px-4 py-2 lg2:py-4 md:overflow-y-auto md:scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ${
                allSelectedAreMobileLink ? "w-full" : "lg2:w-1/2"
              }`}
              ref={eventRef}
            >
              {/* RIGHT SIDE - HEADER */}
              {layout.eTicketDataField?.isOpen &&
                Array.isArray(layout.eTicketDataField?.btnEvent) &&
                list.map((event: any, eventIndex: any) => {
                  if (layout.eTicketDataField?.btnEvent.includes(event?.id)) {
                    return (
                      <React.Fragment key={eventIndex}>
                        <div
                          className="flex accordion-header event__header bg-violet-700 rounded text-white xl:mb-2.5 max-xl:rounded-b-none title-accordion
                          "
                          id=""
                          data-te-collapse-init
                          data-te-target={`#eTicketEvent${eventIndex}`}
                          aria-expanded="true"
                          aria-controls={`eTicketEvent${eventIndex}`}
                        >
                          <div className="flex w-full">
                            <div className="flex flex-wrap md:flex-nowrap flex-auto max-md:max-w-[calc(100%-2.5rem)]">
                              <div
                                className="flex flex-auto items-center leading-4 md:w-auto md:max-w-[12.5rem] w-full py-2 md:py-3 px-4 text-sm md:border-r border-white/20 ellips-text "
                                title={event && event.name}
                              >
                                <p title={event.name}>{event && event.name}</p>
                              </div>
                              <div className="flex w-full md:w-auto max-md:flex-wrap max-md:px-4 max-md:pb-2 max-md:gap-y-2">
                                <div className="flex items-center md:py-3 md:px-4 max-md:pr-4 text-xs  md:border-r border-white/20">
                                  <IKTSVG
                                    className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                                    svgClassName="w-[.675rem] h-[.75rem]"
                                    path={Calendar}
                                  />
                                  {event &&
                                    moment(event.date).format(
                                      "ddd, DD MMM YYYY"
                                    )}
                                </div>
                                <div className="flex items-center md:py-3 md:px-4 text-xs  md:border-r border-white/20">
                                  <IKTSVG
                                    className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                                    svgClassName="w-[.75rem] h-[.75rem]"
                                    path={ClockOutline}
                                  />
                                  {event && event.time}
                                </div>
                                <div className="flex items-center flex-auto md:py-3 md:px-3 text-xs  border-white/20">
                                  <IKTSVG
                                    className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                                    svgClassName="w-[.7813rem] h-[.7813rem]"
                                    path={Geo}
                                  />
                                  {event && event.address}
                                </div>
                              </div>
                            </div>
                            <div className="flex ml-auto">
                              <button
                                className="group relative flex items-center p-3 px-4 text-base text-gray-800 text-left border-l border-white/20 !transition focus:outline-none [&[aria-expanded='false'] > .icon ]:rotate-180"
                                type="button"
                                data-te-collapse-init
                                data-te-target={`#eTicketEvent${eventIndex}`}
                                aria-expanded="true"
                                aria-controls={`eTicketEvent${eventIndex}`}
                              >
                                <KTSVG
                                  className="flex items-center justify-center fill-white transition group-[[data-te-collapse-collapsed]]:rotate-180"
                                  path="other_icons/Collapse.svg"
                                  svgClassName="w-[.5625rem] h-[.5623rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          id={`eTicketEvent${eventIndex}`}
                          className="accordion-collapse collapse show"
                          data-te-collapse-item
                          data-te-collapse-show
                        >
                          {/* RIGHT INNER DRAG */}
                          {event["eventRecords"].map(
                            (eventRecord: any, eventRecordIndex: any) => {
                              if (
                                (eventRecord.selected &&
                                  eventRecord?.ticketType?.id === "eticket") ||
                                (eventRecord.selected &&
                                  eventRecord?.ticketType?.id === "pdf") ||
                                (eventRecord.selected &&
                                  eventRecord?.ticketType?.id ===
                                    "mobile-qr") ||
                                (eventRecord.selected &&
                                  eventRecord?.ticketType?.id === "mobile-link")
                              ) {
                                const isAllFilesUploaded =
                                  events[eventIndex]?.eventRecords[
                                    eventRecordIndex
                                  ]?.ticketFiles &&
                                  Object.keys(
                                    events[eventIndex]?.eventRecords[
                                      eventRecordIndex
                                    ]?.ticketFiles
                                  ).length &&
                                  Object.keys(
                                    events[eventIndex]?.eventRecords[
                                      eventRecordIndex
                                    ]?.ticketFiles?.filter((obj: any) => {
                                      if (obj?.mobileLink) {
                                        return obj?.isValid;
                                      } else {
                                        return JSON.stringify(obj) !== "{}";
                                      }
                                    })
                                  ).length >=
                                    (eventRecord?.quantity
                                      ? eventRecord?.quantity
                                      : 0) &&
                                  Object.keys(
                                    events[eventIndex]?.eventRecords[
                                      eventRecordIndex
                                    ]?.ticketFiles?.filter((obj: any) => {
                                      if (obj?.mobileLink) {
                                        return obj?.isValid;
                                      } else {
                                        return JSON.stringify(obj) !== "{}";
                                      }
                                    })
                                  ).length > 0
                                    ? true
                                    : false;

                                let ticketFileIndex =
                                  eventRecord?.ticketFiles?.findIndex(
                                    (file: any) => !file?.path
                                  );

                                const getIsDropDisabled = () => {
                                  if (
                                    eventRecord?.ticketType?.id ===
                                    "mobile-link"
                                  ) {
                                    return eventRecord?.pkPass ? false : true;
                                  } else {
                                    return isAllFilesUploaded;
                                  }
                                };

                                return (
                                  <Droppable
                                    droppableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                    key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                    isDropDisabled={getIsDropDisabled()}
                                  >
                                    {(provided: any, snapshot: any) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          className="col-span-full"
                                        >
                                          <div
                                            className="drag_items flex items-center gap-2"
                                            key={ticketFileIndex}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div
                                              className={`md:grid max-md:flex max-md:flex-wrap grid-cols-[25%_1fr_11%_11%] md:grid-cols-[1fr_19%_1fr_19%_6%] border ${
                                                snapshot?.isDraggingOver
                                                  ? "border-green-500"
                                                  : "border-violet-500/10"
                                              }  rounded w-full text-xs mb-2.5 
                                           ${
                                             eventRecord?.errors?.[
                                               "quantity_available"
                                             ] === TicketError
                                               ? "bg-rose-500 bg-opacity-[7%] border-rose-700"
                                               : ""
                                           } ${
                                                isAllFilesUploaded
                                                  ? `!bg-green-600 !bg-opacity-[7%] !border-green-700`
                                                  : `bg-gray-100/50 border-solid`
                                              } `}
                                              key={eventRecordIndex}
                                            >
                                              <div className="p-3.5 col-span-full md:col-span-1 max-md:w-1/2 max-md:py-2 max-md:border-b max-md:border-r">
                                                <div className="text-gray-400">
                                                  Listing ID
                                                </div>
                                                <div>
                                                  {eventRecord?.id
                                                    ? eventRecord?.id
                                                    : "N/A"}
                                                </div>
                                              </div>
                                              <div className="p-3.5 md:border-t-0 max-md:w-1/2 max-md:py-2 max-md:border-b">
                                                <div className="text-gray-400">
                                                  Quantity
                                                </div>
                                                <div>
                                                  {eventRecord?.quantity
                                                    ? eventRecord?.quantity
                                                    : "0"}
                                                </div>
                                              </div>
                                              <div className="p-3.5 col-span-full md:col-span-1 max-md:w-full max-md:py-2 max-md:border-b">
                                                <div className="text-gray-400">
                                                  Ticket details
                                                </div>
                                                <div>
                                                  {(() => {
                                                    const categoryName =
                                                      getCategoryValue(
                                                        eventRecord,
                                                        event
                                                      )?.name;
                                                    const sectionName =
                                                      getSectionValue(
                                                        eventRecord,
                                                        event
                                                      )?.name;

                                                    return categoryName
                                                      ? `${categoryName}, ${
                                                          sectionName || "N/A"
                                                        }`
                                                      : "N/A";
                                                  })()}
                                                </div>
                                              </div>
                                              <div className="p-3.5  md:border-t-0 max-md:w-[calc(100%-2.5rem)] max-md:py-2 ">
                                                <div className="text-gray-400">
                                                  Row (Seat)
                                                </div>
                                                <div>
                                                  {eventRecord?.row
                                                    ? eventRecord?.row
                                                    : "0"}{" "}
                                                  (
                                                  {eventRecord?.firstSeat
                                                    ? eventRecord?.firstSeat
                                                    : "0"}
                                                  )
                                                </div>
                                              </div>

                                              {/* RIGHT SIDE BUTTONS (INFO & UPLOAD) */}
                                              {/* <div
                                        // className="flex items-center justify-center border-t md:border-t-0 border-l border-dashed border-violet-500/10 p-3.5"
                                        className={`flex items-center justify-center border-t md:border-t-0 border-l border-solid  p-3.5 ${
                                          isAllFilesUploaded &&
                                          `!border-green-700`
                                        } ${
                                          eventRecord?.errors?.[
                                            "quantity_available"
                                          ] === TicketError && "border-rose-700"
                                        }`}
                                      >
                                        <Tooltip
                                          anchorId={`assigned-tooltip${eventIndex}${eventRecordIndex}`}
                                          // content="1/2 tickets assigned"
                                          content={`${
                                            events[eventIndex]?.eventRecords[
                                              eventRecordIndex
                                            ]?.ticketFiles &&
                                            Object.keys(
                                              events[eventIndex]?.eventRecords[
                                                eventRecordIndex
                                              ]?.ticketFiles?.filter(
                                                (obj: any) =>
                                                  JSON.stringify(obj) !== "{}"
                                              )
                                            )?.length > 0
                                              ? events[
                                                  eventIndex
                                                ]?.eventRecords[
                                                  eventRecordIndex
                                                ]?.ticketFiles.filter(
                                                  (obj: any) =>
                                                    JSON.stringify(obj) !== "{}"
                                                )?.length
                                              : 0
                                          } of ${
                                            eventRecord?.quantity
                                              ? eventRecord?.quantity
                                              : 0
                                          }  tickets assigned `}
                                          place="top"
                                          variant="light"
                                          className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                          clickable
                                        />
                                        <button
                                          id={`assigned-tooltip${eventIndex}${eventRecordIndex}`}
                                        >
                                          <KTSVG
                                            // className="fill-violet-500 hover:fill-indigo-500 transition"
                                            className={`${
                                              events[eventIndex]?.eventRecords[
                                                eventRecordIndex
                                              ]?.ticketFiles &&
                                              isAllFilesUploaded
                                                ? `fill-green-600 hover:fill-green-500 transition`
                                                : `fill-violet-500 hover:fill-indigo-500 transition`
                                            } `}
                                            svgClassName="w-[.875rem] h-[.875rem]"
                                            path="other_icons/info.svg"
                                          />
                                        </button>
                                      </div> */}
                                              <div
                                                className={`flex items-center justify-center border-t md:border-t-0 border-l border-solid  p-3.5 ${
                                                  isAllFilesUploaded
                                                    ? `!border-green-700`
                                                    : ""
                                                } ${
                                                  eventRecord?.errors?.[
                                                    "quantity_available"
                                                  ] === TicketError
                                                    ? "border-rose-700"
                                                    : ""
                                                } max-md:w-10 max-md:py-2 max-md:border-t-0`}
                                              >
                                                <TooltipPortal
                                                  id={`upload${eventIndex}${eventRecordIndex}`}
                                                  content={uploadTicketTooltipWithAssignTickets(
                                                    eventIndex,
                                                    eventRecordIndex,
                                                    eventRecord,
                                                    events
                                                  )}
                                                  place="top"
                                                  variant="light"
                                                  className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-[99999]"
                                                />
                                                <button
                                                  className=""
                                                  type="button"
                                                  disabled={
                                                    isAllFilesUploaded ||
                                                    eventRecord?.quantity <= 0
                                                  }
                                                  data-tooltip-id={`upload${eventIndex}${eventRecordIndex}`}
                                                >
                                                  <IKTSVG
                                                    className={`${
                                                      isAllFilesUploaded
                                                        ? `fill-green-600 hover:fill-green-500 transition`
                                                        : `fill-violet-500 hover:fill-indigo-500 transition`
                                                    } `}
                                                    path={
                                                      eventRecord?.ticketType
                                                        ?.id === "mobile-link"
                                                        ? mobileSVG
                                                        : pin
                                                    }
                                                    svgClassName="w-[.6875rem] h-[.875rem]"
                                                  />
                                                </button>
                                              </div>
                                              {/* END --------------------- */}

                                              {/* BODY */}
                                              {eventRecord?.quantity > 0 &&
                                                (eventRecord?.ticketType?.id ===
                                                  "eticket" ||
                                                  eventRecord?.ticketType
                                                    ?.id === "pdf" ||
                                                  eventRecord?.ticketType
                                                    ?.id === "mobile-qr") && (
                                                  <div
                                                    className={`px-1.5 col-span-full w-full border-t border-solid flex flex-col flex-wrap gap-2.5 py-2.5 ${
                                                      isAllFilesUploaded
                                                        ? `!border-green-700`
                                                        : `border-gray-200`
                                                    } ${
                                                      eventRecord?.errors?.[
                                                        "quantity_available"
                                                      ] === TicketError &&
                                                      "border-rose-700"
                                                    }`}
                                                  >
                                                    {events[
                                                      eventIndex
                                                    ]?.eventRecords?.[
                                                      eventRecordIndex
                                                    ]?.ticketFiles?.map(
                                                      (
                                                        ticketFile: any,
                                                        ticketFileIndex: any
                                                      ) => {
                                                        let isPathExist =
                                                          ticketFile?.path;

                                                        return (
                                                          <div className="drag_items px-2 flex items-center gap-2">
                                                            <span>
                                                              Ticket{" "}
                                                              {ticketFileIndex +
                                                                1}
                                                            </span>
                                                            <div className="single_drag flex flex-wrap col-span-full items-center flex-1 ">
                                                              <div
                                                                className={`${
                                                                  isAllFilesUploaded &&
                                                                  "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                                } ${
                                                                  isPathExist &&
                                                                  "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                                }  border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition`}
                                                              >
                                                                <span
                                                                  className={`${
                                                                    isAllFilesUploaded &&
                                                                    "!text-black !inline-flex items-center justify-between w-full"
                                                                  }  ${
                                                                    isPathExist &&
                                                                    "!text-black !inline-flex items-center justify-between w-full"
                                                                  } flex justify-between   text-sm12 font-medium text-gray-400 block `}
                                                                >
                                                                  {isPathExist
                                                                    ? isPathExist
                                                                    : " Drag file here"}

                                                                  {isPathExist && (
                                                                    <span
                                                                      key={`key-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                      className="flex items-center gap-x-2"
                                                                    >
                                                                      <button
                                                                        onClick={() =>
                                                                          pdfViewer(
                                                                            ticketFile
                                                                          )
                                                                        }
                                                                        id={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                      >
                                                                        <KTSVG
                                                                          className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                          path="other_icons/Eye.svg"
                                                                          svgClassName="w-4 h-[.6875rem]"
                                                                        />
                                                                        <Tooltip
                                                                          anchorId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                          content="View"
                                                                          place="top"
                                                                          variant="light"
                                                                          className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                        />
                                                                      </button>

                                                                      <button
                                                                        // className="h-[0.5625rem]"
                                                                        onClick={() =>
                                                                          deletePdfRightSide(
                                                                            ticketFile,
                                                                            ticketFileIndex,
                                                                            eventRecordIndex,
                                                                            eventIndex
                                                                          )
                                                                        }
                                                                        id={`delete${eventIndex}${eventRecordIndex}-${ticketFileIndex}`}
                                                                      >
                                                                        <Tooltip
                                                                          anchorId={`delete${eventIndex}${eventRecordIndex}-${ticketFileIndex}`}
                                                                          content="Delete"
                                                                          place="top"
                                                                          variant="light"
                                                                          className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                        />
                                                                        {files.some(
                                                                          (
                                                                            file: any
                                                                          ) =>
                                                                            file.id ===
                                                                            ticketFile.id
                                                                        ) ? (
                                                                          <IKTSVG
                                                                            className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                            path={
                                                                              remove
                                                                            }
                                                                            svgClassName="w-[.5625rem] h-[.5938rem]"
                                                                          />
                                                                        ) : (
                                                                          <KTSVG
                                                                            className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                            path={
                                                                              "other_icons/Delete.svg"
                                                                            }
                                                                            svgClassName="w-2.5 h-[.8125rem]"
                                                                          />
                                                                        )}
                                                                      </button>
                                                                      <Tooltip
                                                                        anchorId={`delete${eventIndex}${eventRecordIndex}-${ticketFileIndex}`}
                                                                        content={
                                                                          "Remove"
                                                                        }
                                                                        place="top"
                                                                        variant="light"
                                                                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                      />
                                                                    </span>
                                                                  )}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                )}

                                              {/* MOBILE LINK */}
                                              {eventRecord?.quantity > 0 &&
                                                eventRecord?.ticketType?.id ===
                                                  "mobile-link" && (
                                                  <>
                                                    {/* PKPASS TOGGLE */}
                                                    <div className="px-1.5 col-span-full w-full border-t border-solid flex flex-row flex-wrap gap-2.5 py-2.5">
                                                      <div className="flex items-center px-2">
                                                        <span className="font-medium mr-4">
                                                          Separate links for
                                                          Android/iOS
                                                        </span>
                                                        <div>
                                                          <label
                                                            className={`relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false ${
                                                              eventRecord?.pkPass &&
                                                              "pointer-events-none  "
                                                            }`}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              name={`separate_mobile_link-${eventIndex}-${eventRecordIndex}`}
                                                              className="sr-only peer"
                                                              checked={
                                                                eventRecord?.separate_links
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) =>
                                                                handleSeparateLinks(
                                                                  e,
                                                                  eventIndex,
                                                                  eventRecordIndex
                                                                )
                                                              }
                                                            />
                                                            <div
                                                              className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8  ${
                                                                eventRecord?.pkPass &&
                                                                "pointer-events-none after:!bg-gray-400 "
                                                              }`}
                                                            ></div>
                                                          </label>
                                                        </div>
                                                      </div>

                                                      <div className="flex items-center px-2  ">
                                                        <span className="font-medium mr-4">
                                                          PKPASS
                                                        </span>
                                                        <div>
                                                          <label className="relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false">
                                                            <input
                                                              type="checkbox"
                                                              name={`pkPass-${eventIndex}-${eventRecordIndex}`}
                                                              className="sr-only peer"
                                                              checked={
                                                                eventRecord?.pkPass
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) =>
                                                                handlePKPASS(
                                                                  e,
                                                                  eventIndex,
                                                                  eventRecordIndex
                                                                )
                                                              }
                                                            />
                                                            <div className="bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8"></div>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* PKPASS - CONDITION (DRAG / TEXT) */}
                                                    {eventRecord?.pkPass ? (
                                                      <>
                                                        <div className="px-1.5 col-span-full w-full border-t border-solid flex flex-col flex-wrap gap-2.5 py-2.5">
                                                          {events[
                                                            eventIndex
                                                          ]?.eventRecords?.[
                                                            eventRecordIndex
                                                          ]?.ticketFiles?.map(
                                                            (
                                                              ticketFile: any,
                                                              ticketFileIndex: any
                                                            ) => {
                                                              return (
                                                                <div className="drag_items px-2 flex items-center gap-2">
                                                                  <span>
                                                                    {" "}
                                                                    Ticket{" "}
                                                                    {ticketFileIndex +
                                                                      1}
                                                                  </span>
                                                                  <div className="single_drag flex flex-wrap col-span-full items-center flex-1 ">
                                                                    <div
                                                                      className={`  ${
                                                                        ticketFile?.path &&
                                                                        !ticketFile?.mobileLink
                                                                          ? "border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition border-green-500"
                                                                          : ticketFile?.path &&
                                                                            ticketFile?.mobileLink
                                                                          ? "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition  border rounded bg-white px-2 py-[3px] flex-1 "
                                                                          : "min-h-[1.5rem] border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition"
                                                                      } ${
                                                                        ticketFile
                                                                          ?.ticketFiles
                                                                          ?.path &&
                                                                        !ticketFile.hasOwnProperty(
                                                                          "isValid"
                                                                        ) &&
                                                                        "border-1 !border-dashed !border-green-500"
                                                                      }   `}
                                                                    >
                                                                      <span
                                                                        className={`text-sm12 font-medium block ${
                                                                          ticketFile?.path &&
                                                                          !ticketFile?.mobileLink
                                                                            ? ""
                                                                            : ticketFile?.path &&
                                                                              ticketFile?.mobileLink
                                                                            ? "!text-black !inline-flex items-center justify-between w-full falsetext-sm12 font-medium"
                                                                            : "text-gray-400"
                                                                        }`}
                                                                      >
                                                                        {ticketFile?.path &&
                                                                        !ticketFile.hasOwnProperty(
                                                                          "isValid"
                                                                        ) ? (
                                                                          <div className="flex items-center flex-nowrap">
                                                                            <span className="flex-none mr-2.5">
                                                                              Generating
                                                                              URL
                                                                            </span>
                                                                            <span className="flex-1">
                                                                              <div className="page-loader">
                                                                                <div className="loader-stripe bg-gray-200 rounded w-full h-2 ">
                                                                                  <div
                                                                                    className="load-line h-full bg-green-500"
                                                                                    style={{
                                                                                      animation: ` loaderAnim 2s linear forwards`,
                                                                                    }}
                                                                                  ></div>
                                                                                </div>
                                                                              </div>
                                                                            </span>
                                                                          </div>
                                                                        ) : ticketFile?.path &&
                                                                          ticketFile?.mobileLink ? (
                                                                          <>
                                                                            {ticketFile?.mobileLink
                                                                              ?.split(
                                                                                "/"
                                                                              )
                                                                              .pop()}
                                                                            <span className="flex s-center gap-x-2 ">
                                                                              <button
                                                                                type="button"
                                                                                id={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                onClick={() => {
                                                                                  let copiedValues =
                                                                                    copy(
                                                                                      ticketFile?.mobileLink
                                                                                    );
                                                                                  setIsCopied(
                                                                                    {
                                                                                      id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`,
                                                                                      value:
                                                                                        copiedValues,
                                                                                    }
                                                                                  );
                                                                                  setTimeout(
                                                                                    () => {
                                                                                      setIsCopied(
                                                                                        {
                                                                                          id: "",
                                                                                          value:
                                                                                            "",
                                                                                        }
                                                                                      );
                                                                                    },
                                                                                    200
                                                                                  );
                                                                                }}
                                                                              >
                                                                                <IKTSVG
                                                                                  className={`flex items-center justify-center transition ${
                                                                                    isCopied.value ===
                                                                                      ticketFile?.mobileLink &&
                                                                                    isCopied.id ===
                                                                                      `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`
                                                                                      ? "fill-green-600  hover:fill-green-600"
                                                                                      : "fill-violet-500 hover:fill-indigo-500"
                                                                                  }`}
                                                                                  path={
                                                                                    CloneSVG
                                                                                  }
                                                                                  svgClassName="w-[1rem] h-[.6875rem]"
                                                                                />
                                                                                <Tooltip
                                                                                  anchorId={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                  content="Copy"
                                                                                  place="top"
                                                                                  variant="light"
                                                                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                />
                                                                              </button>

                                                                              <button
                                                                                type="button"
                                                                                className="h-[0.5625rem]"
                                                                                id={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                onClick={(
                                                                                  e: any
                                                                                ) =>
                                                                                  handleMobileLinkRemove(
                                                                                    eventIndex,
                                                                                    eventRecordIndex,
                                                                                    ticketFileIndex
                                                                                  )
                                                                                }
                                                                              >
                                                                                <IKTSVG
                                                                                  className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                                  path={
                                                                                    remove
                                                                                  }
                                                                                  svgClassName="w-[.5938rem]  h-[.5938rem]"
                                                                                />
                                                                              </button>
                                                                              <Tooltip
                                                                                anchorId={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                content={
                                                                                  files.some(
                                                                                    (
                                                                                      file: any
                                                                                    ) =>
                                                                                      file.id ===
                                                                                      ticketFile.id
                                                                                  )
                                                                                    ? "Unassign"
                                                                                    : "Remove"
                                                                                }
                                                                                place="top"
                                                                                variant="light"
                                                                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                              />
                                                                            </span>
                                                                          </>
                                                                        ) : (
                                                                          "Drop PKPASS file here"
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <MobileUrlLink
                                                        isOpen={{
                                                          event: events,
                                                        }}
                                                        eventIndex={eventIndex}
                                                        eventRecordIndex={
                                                          eventRecordIndex
                                                        }
                                                        handleMobileLinkChange={
                                                          handleMobileLinkChange
                                                        }
                                                        setIsCopied={
                                                          setIsCopied
                                                        }
                                                        copy={copy}
                                                        isCopied={isCopied}
                                                        eventRecord={
                                                          eventRecord
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                )}
                                            </div>
                                          </div>
                                          {/* PRE UPLOAD */}
                                          <PreUpload
                                            eventRecord={eventRecord}
                                            eventIndex={eventIndex}
                                            eventRecordIndex={eventRecordIndex}
                                            instructionLetterTemplates={
                                              instructionLetterTemplates
                                            }
                                            templateState={templateState}
                                            setTemplateState={setTemplateState}
                                            handleChangeTemplate={
                                              handleChangeTemplate
                                            }
                                            handleInputChanges={
                                              handleInputChanges
                                            }
                                            deletePdfRightSide={
                                              deletePdfRightSide
                                            }
                                            pdfView={pdfViewer}
                                            files={files}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                );
                              }
                            }
                          )}
                        </div>
                      </React.Fragment>
                    );
                  }
                })}
            </div>
          </div>

          <div className="inline-flex items-center justify-end border-t py-2.5 px-5 text-sm13 w-full">
            <button
              type="button"
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleEventCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={` ${
                confirmButtonDisabled(
                  getUploadedListing()[0] !== getUploadedListing()[1]
                ) &&
                "pointer-events-none !border-0 !text-gray-400 !bg-gray-100 "
              } border border-green-500 bg-green-500 text-white hover:bg-white hover:text-green-500 rounded hover:border hover:border-green-500 transition p-0.5 px-2.5`}
              data-bs-dismiss="offcanvas"
              onClick={handleEventConfirm}
            >
              Confirm
            </button>
            {/* <span className="inline-block my-0.5">
              Upload to {getUploadedListing()[0]}/{getUploadedListing()[1]}{" "}
              listings
            </span> */}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default ETicketUpload;
