/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _, { debounce, isArray } from "lodash";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import { Virtuoso } from "react-virtuoso";
import HaxPublishGray from "../../../../assets/media/icons/other_icons/hax-Publish-Gray.svg";
import LockIconOpen from "../../../../assets/media/icons/other_icons/lock-open-solid.svg";
import LockIcon from "../../../../assets/media/icons/other_icons/lock-solid.svg";
import unpublish from "../../../../assets/media/icons/other_icons/new-unpublish.svg";
import AngleLeft from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import ListingLinesSVG from "../../../../assets/media/icons/standard_icons/listing_lines.svg";
import NoJSXComponent from "../../../../components/noJSX/NoJSXComponent";
import TablePopup from "../../../../components/tableComponent/TablePopup";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { showAlert } from "../../../../helpers/ShowAlert";
import { display } from "../../../tx_trade/helper/Assist";
import { InventoryContext } from "../../core/InventoryProvider";
import { bulkUploadCount } from "../../core/_functions";
import { Messages } from "../../core/_messages";
import { handleBulk } from "../../core/_request";
import EventField from "./EventField";
import EventFieldView from "./EventFieldView";
import EventFieldViewBulkEdit from "./EventFieldViewBulkEdit";
import EventFieldViewMobile from "./EventFieldViewMobile";
import EventHeaderCell from "./EventHeaderCell";
import HeaderCellWrapper from "./HeaderCellWrapper";

const EventFields3 = memo(
  ({
    list,
    eventLoader,
    event,
    eIndex,
    setList,
    loadMoreListing,
    loadAllEventListing,
    updateVisibility,
    tableRefSide,
    editData,
    setEditData,
    setIsOpenTicketPopUp,
    matchIndexView,
    handleSortAction,
    currentRecordId,
    setCurrentRecordId,
    SortingCurrentID,
    setSortingCurrentID,
    setIsOpenListingQualityPopup,
    setEditView,
  }: any) => {
    const { isGodAdmin, isMobile } = NoJSXComponent();
    const tableRef: any = useRef();
    const btnRef: any = useRef(null);
    const headerRef: any = useRef(null);
    const layout = useContext(InventoryContext);
    const isTableEditable: [] = layout.isTableEditable;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableAlert, setTableAlert] = useState<boolean>(false);
    const virtuosoRef = useRef<any>(null);

    let disabledAll =
      _.size(layout?.isTableEditable) > 0 ||
      layout.isDeleteActive ||
      layout.isEditPopUpActive ||
      layout.isBulkActionLoader ||
      _.size(layout?.tableAlert?.confirm);

    const div1 = document.getElementById(`loadHead${event?.id}`) as HTMLElement;
    const div2 = document.getElementById(`${event?.id}lazyLoad`) as HTMLElement;
    function syncHorizontalScroll(event: any) {
      if (event.target === div1) {
        div2.scrollLeft = div1.scrollLeft;
      } else {
        div1.scrollLeft = div2.scrollLeft;
      }
    }
    div1?.addEventListener("scroll", syncHorizontalScroll);
    div2?.addEventListener("scroll", syncHorizontalScroll);

    const handleScrollVirtuoso = useCallback(
      (eventTarget: any) => {
        const eventListing: any = document.getElementById(
          eventTarget.target?.id
        );

        const firstChildElement = eventListing?.firstElementChild;
        const firstChildFirstChildElement =
          firstChildElement?.firstElementChild;
        const firstChildFirstChildElement2 =
          firstChildFirstChildElement?.firstElementChild;
        const childOffset = firstChildFirstChildElement2?.offsetTop;
        const rect = firstChildFirstChildElement2?.getBoundingClientRect();

        const eventContainer: any =
          eventListing?.parentElement?.parentElement?.parentElement
            ?.parentElement?.parentElement?.parentElement?.parentElement;
        const topPositionEvent = eventContainer?.getBoundingClientRect().top;

        const scrollContainer = document.getElementById(
          `${event.id}lazyLoad`
        ) as HTMLElement;

        if (eventListing && event.total_listings > 20) {
          const filters_block: any =
            document.getElementById("filters_block")?.clientHeight;
          const inventoryFilter: any =
            document.getElementById("InventoryFilter")?.clientHeight;
          const HeaderHeight: any =
            document.querySelector("header")?.clientHeight;
          const topOffset: any =
            filters_block + inventoryFilter + HeaderHeight + 10;
        }

        const isOpenAnyEvent = list?.filter((obj: any) => obj?.isOpen === true);
        const eventId = isOpenAnyEvent?.[0]?.id;
        if (childOffset < 1) {
          document.body?.classList.remove("overflow-hidden");
        }
      },
      [list]
    );
    const debouncedHandleScroll = debounce(handleScrollVirtuoso, 0);
    const [flag, setFlag] = useState(false);

    let forRowDisabled: any;

    // Table horizontal scroll
    const scroll = (scrollOffset: number, eventId: string) => {
      const load = document.getElementById(
        `${eventId}lazyLoad`
      ) as HTMLElement | null;
      const loadHead = document.getElementById(
        `loadHead${eventId}`
      ) as HTMLElement | null;

      if (load && loadHead) {
        load.scrollLeft += scrollOffset;
        loadHead.scrollLeft += scrollOffset;
      } else {
      }
    };

    // Handl Select ALL Checkboxes
    const handleSelectAll = async (data: any, eIndex: number) => {
      let dataChecked = data.target.checked;

      setList((prev: any) => {
        return prev.map((event: any, eInnerIndex: any) => {
          if (eIndex === eInnerIndex && isGodAdmin === false) {
            let eventRecord = Array.isArray(event["eventRecords"])
              ? event["eventRecords"].map((eventRecord: any) => {
                  return {
                    ...eventRecord,
                    replacible:
                      _.size(eventRecord?.replacible) === 0
                        ? eventRecord
                        : eventRecord?.replacible,
                    selected: eventRecord?.processing
                      ? false
                      : eventRecord?.hold_tickets?.length > 0
                      ? false
                      : dataChecked,
                  };
                })
              : [];
            if (
              event?.eventRecords &&
              event?.total_listings > _.size(event?.eventRecords) &&
              dataChecked
            ) {
              return {
                ...event,
                eventRecords: eventRecord,
                requireSelectAll: {
                  selectedListings: _.size(event?.eventRecords),
                  total_listings: event?.total_listings,
                },
              };
            } else {
              return {
                ...event,
                eventRecords: eventRecord,
                requireSelectAll: {},
              };
            }
          }
          // GOD ADMIN
          else if (eIndex === eInnerIndex && isGodAdmin) {
            let eventRecord = event?.["eventRecords"]?.map(
              (eventRecord: any) => {
                if (eventRecord?.isLock === false) {
                  return {
                    ...eventRecord,
                    selected: eventRecord?.processing
                      ? false
                      : eventRecord?.hold_tickets?.length > 0
                      ? false
                      : data.target.checked,
                  };
                } else {
                  return {
                    ...eventRecord,
                    selected: false,
                  };
                }
              }
            );

            if (
              event?.eventRecords &&
              event?.total_listings > _.size(event?.eventRecords) &&
              dataChecked
            ) {
              return {
                ...event,
                eventRecords: eventRecord,
                requireSelectAll: {
                  selectedListings: _.size(event?.eventRecords),
                  total_listings: event?.total_listings,
                },
              };
            } else {
              return {
                ...event,
                eventRecords: eventRecord,
                requireSelectAll: {},
              };
            }
          } else {
            return event;
          }
        });
      });
    };

    const confirmListingDelete = (id: any) => {
      handleListingAction("delete", id);
    };

    //SET EVENT RECORD LOADER
    const setEventRecordLoader = async (eventRecord: any, status: boolean) => {
      setList((events: any) => {
        return events.map((inEvent: any) => {
          if (inEvent.id === event.id) {
            return {
              ...inEvent,
              eventRecords: Array.isArray(inEvent["eventRecords"])
                ? inEvent["eventRecords"].map((inEventRecord: any) => {
                    if (eventRecord?.id === inEventRecord?.id) {
                      return {
                        ...inEventRecord,
                        loader: status,
                      };
                    } else {
                      return inEventRecord;
                    }
                  })
                : [],
            };
          } else {
            return inEvent;
          }
        });
      });
    };

    // HANDLE PUBLISH, UNPUBLISH AND DELETE
    const handleListingAction = async (action: any, id: number) => {
      setEventRecordLoader({ id: id }, true);

      handleBulk({ checked_ids: id, action }).then((response: any) => {
        setTimeout(() => {
          setEventRecordLoader({ id: id }, false);
        }, 2000);

        if (Object.keys(response?.data?.errors).length === 0) {
          showAlert(response?.message, false);
        } else {
          Object.values(response?.data?.errors).forEach((error: any) => {
            showAlert(error, true);
          });
        }
      });
    };

    const getColumnItems = useMemo(() => {
      return layout.columnItems.filter(
        (columnItem: any) => columnItem.checked === true
      );
    }, [layout.columnItems]);

    // HANDLE ETICKET FUNCTION
    const handleEticket = (event: any, eventRecord: any) => {
      eventRecord.selected = true;

      layout.setTempEvent(list);
      layout.setETicketDataField((currentData: any) => {
        let eventSelected = list
          .map((event: any) => {
            return {
              ...event,
              eventRecords: Array.isArray(event["eventRecords"])
                ? event["eventRecords"].filter((inEventRecord: any) => {
                    return (
                      (inEventRecord?.selected === true ||
                        eventRecord?.id === inEventRecord?.id) &&
                      (inEventRecord?.ticket_type === "eticket" ||
                        inEventRecord?.ticket_type?.id === "eticket" ||
                        inEventRecord?.ticket_type?.id === "pdf" ||
                        inEventRecord?.ticket_type?.id === "mobile-qr" ||
                        inEventRecord?.ticket_type?.id === "mobile-link" ||
                        inEventRecord?.ticket_type === "mobile-link")
                    );
                  })
                : [],
            };
          })
          .filter((event: any) => {
            return (
              Array.isArray(event["eventRecords"]) &&
              event["eventRecords"].length > 0
            );
          });
        return {
          btnEvent:
            eventSelected && eventSelected.length > 0
              ? _.map(eventSelected, "id")
              : _.map(event, "id"),
          isOpen: true,
        };
      });
    };

    useEffect(() => {
      const keyDownHandler = (event: any) => {
        if (event.key === "Enter" && btnRef && btnRef.current !== null) {
          btnRef.current.click();
          setTimeout(() => {
            setTableAlert(false);
          }, 300);
          setFlag(true);
        }
      };
      if (flag === true) {
        setFlag(false);
      }
      document.addEventListener("keydown", keyDownHandler);
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [flag]);

    // GET SUB TICKET TYPE
    const subTicketType = useMemo(() => {
      return layout.ticketType?.filter(
        (item: any) => item?.id === "eticket"
      )?.[0]?.subtypes;
    }, [layout.ticketType]);

    useEffect(() => {
      const element = document.getElementById("tableId1");
      if (element) {
        const elem1 = document.querySelectorAll("#left-shad-list");
        const elem2 = document.querySelectorAll("#checkbox_shad");
        element?.firstChild?.addEventListener("scroll", () => {
          const elementScrollLeft = element.scrollLeft;
          const elementScrollRight =
            element.scrollWidth - element.clientWidth - elementScrollLeft;

          elem1.forEach((element: any, index: any) => {
            elementScrollRight === 0
              ? element.classList.remove("left-shad")
              : element.classList.add("left-shad");
          });
          elem2.forEach((element: any, index: any) => {
            elementScrollLeft === 0
              ? element.classList.remove("shar-right")
              : element.classList.add("shar-right");
          });
        });
      }
    }, [event]);

    const scrollContainer = document.getElementById(`${event.id}lazyLoad`);
    let prevScrollTop = 0;
    let down: any = document.querySelectorAll(".bottom_load");
    let up: any = document.querySelectorAll(".top_load");

    scrollContainer?.addEventListener("scroll", function () {
      const currentScrollTop = scrollContainer.scrollTop;
      if (currentScrollTop > prevScrollTop) {
        // scrolling down
        up?.forEach((item: any) => {
          item?.classList.add("hidden");
        });
        down?.forEach((item: any) => {
          item?.classList.remove("hidden");
        });
      } else if (currentScrollTop < prevScrollTop) {
        // scrolling up
        up?.forEach((item: any) => {
          item?.classList.remove("hidden");
        });
        down?.forEach((item: any) => {
          item?.classList.add("hidden");
        });
      }
      prevScrollTop = currentScrollTop;
    });

    const firstSelectedIndex: any = event?.eventRecords?.find(
      (record: any) => record?.selected === true
    );

    const handleOnUnLockAll = (e: any, eId: any) => {
      setList((prev: any) => {
        return prev.map((event: any, eInnerIndex: any) => {
          if (event?.id === eId) {
            return {
              ...event,
              eventRecords: Array.isArray(event["eventRecords"])
                ? event["eventRecords"].map((eventRecord: any) => {
                    return {
                      ...eventRecord,
                      isLock: e?.target?.checked ? false : true,
                      selected: false,
                    };
                  })
                : [],
            };
          } else {
            return event;
          }
        });
      });
    };

    const isAllb = useMemo(() => {
      return list?.map((obj: any) =>
        obj?.eventRecords?.every((innerobj: any) => innerobj?.isLock === false)
      );
    }, [list]);

    const isAllSumOf = list?.map((obj: any) =>
      obj?.eventRecords?.some((innerobj: any) => innerobj?.isLock === false)
    );

    // CONFIRM SELECT ALL BUTTON ADDED
    const handleSelectLoadAll = async (data: any, event: any, eIndex: any) => {
      let dataChecked = true;

      if (list[eIndex]) {
        let meta = list[eIndex]["meta"];
        let currentPage = meta["current_page"] > 1 ? 1 : meta["current_page"];
        let lastPage = meta["last_page"];
        let count: any = [];

        for (currentPage; currentPage <= lastPage; currentPage++) {
          count.push(currentPage);
        }
        if (count.length > 0) {
          await loadAllEventListing(event, count.length);
        }

        setList((prev: any) => {
          return prev.map((event: any, eInnerIndex: any) => {
            if (eIndex === eInnerIndex) {
              return {
                ...event,
                requireSelectAll: {},
                eventRecords: Array.isArray(event["eventRecords"])
                  ? event["eventRecords"].map((eventRecord: any) => {
                      return {
                        ...eventRecord,
                        selected: eventRecord?.processing
                          ? false
                          : eventRecord?.hold_tickets?.length > 0
                          ? false
                          : dataChecked,
                        isLock: false,
                      };
                    })
                  : [],
              };
            } else {
              return event;
            }
          });
        });
      }
    };

    const selectedCounts = list?.map((obj: any) => {
      const count = obj?.eventRecords?.filter(
        (record: any) => record?.selected
      )?.length;
      return count;
    });

    useEffect(() => {
      body.classList.add("inventory-page");
      function handleBoxScroll() {
        var box: any = document.getElementById(`${event.id}lazyLoad`);
        var boxFull = box.parentNode;
        var content: any = box?.querySelector(
          '[data-test-id="virtuoso-item-list"]'
        );
        var scrollTop: any = box?.scrollTop;
        var scrollHeight: any = content?.scrollHeight;
        var clientHeight: any = box?.clientHeight;

        // If scrolled to the top, add class
        if (scrollTop > 0) {
          boxFull?.classList.add("highlightBoth");
          boxFull?.classList.remove("NohighlightBottom");
          boxFull?.classList.remove("NohighlightTop");
          // console.log("1");

          // console.log("first");
        }
        if (scrollTop < 1) {
          boxFull?.classList.add("NohighlightTop");
          boxFull?.classList.add("highlightBoth");
          boxFull?.classList.remove("NohighlightBottom");
          // console.log("2", boxFull);
        }

        // If scrolled to the bottom, add class
        if (scrollTop + clientHeight >= scrollHeight) {
          // console.log("3");
          boxFull?.classList.add("NohighlightBottom");
          boxFull?.classList.add("highlightBoth");
          boxFull?.classList.remove("NohighlightTop");
          // console.log("012");
        }
      }

      // Attach scroll event listener to the box
      var scrollableBox: any = document.getElementById(`${event.id}lazyLoad`);
      scrollableBox?.addEventListener("scroll", handleBoxScroll);
    });

    let body: any = document.querySelector("body");
    let isMac: any = navigator.platform.indexOf("Mac") !== -1;

    isMac && body?.classList?.add("mac");

    // UDPATE ID ON HOVER
    const updateIdOnHover = (data: any) => {
      setCurrentRecordId(data);
    };
    // -------------------------------------------------------------------------
    //  NEW LAZY LOAD BASED ON WINDOW SCROLL
    // -------------------------------------------------------------------------

    useEffect(() => {
      const extraPaddingElement: HTMLElement | null =
        document.getElementById("extra-padding");

      if (event?.isOpen && Number(event?.total_listings) > 20) {
        if (extraPaddingElement) {
          extraPaddingElement.style.display = "none";
        }
      } else {
        if (extraPaddingElement) {
          extraPaddingElement.style.display = "block";
        }
      }
    }, [event]);

    useEffect(() => {
      const scrollToRow = async () => {
        if (
          layout.virtuosoRecordIndex.virtuosoId !== null &&
          layout.virtuosoRecordIndex.eventId === event.id
        ) {
          await virtuosoRef.current.scrollToIndex({
            index: layout.virtuosoRecordIndex.virtuosoId,
            align: "center",
            behavior: "smooth",
          });

          // Use Promise to wait for 1 second
          const delay = (ms: any) =>
            new Promise((resolve) => setTimeout(resolve, ms));
          await delay(200); // Wait for 1 second

          layout.setVirtuosoRecordIndex({});
        }
      };

      scrollToRow();
    }, [layout.virtuosoRecordIndex.virtuosoId]);

    // HEADER CHECKBOX VALUE
    const headerCheckboxValue = useMemo(() => {
      if (!event["eventRecords"] || !isArray(event["eventRecords"])) {
        return false;
      }
      const filteredRecords = event["eventRecords"].filter(
        (item) => !item?.processing
      );
      if (filteredRecords.length === 0) {
        return false;
      }
      return filteredRecords.every(
        (item) => item?.selected === true && !item?.hold_tickets?.length
      );
    }, [event]);

    return (
      <React.Fragment key={"eventFields" + event?.id}>
        {tableAlert && (
          <TablePopup
            btnRef={btnRef}
            isConfirm={true}
            message={"Are you sure you want to delete?"}
            cancelBtn={() => setTableAlert(false)}
            confirmBtn={() => confirmListingDelete(tableAlert)}
          />
        )}

        <div
          id={`EventCollapseBody${event?.id}`}
          className={`bottom_acc border-b hidden`}
          aria-labelledby="bodyOne"
          data-te-collapse-item
        >
          <div className="accordion-body relative">
            <div
              ref={tableRef}
              className="scroll-smooth inventory-main inventory-table scrollbar-none scrollbar-thumb-violet-500 scrollbar-thumb-rounded-full rounded-b noscroll-x"
              id={`tableId1`}
            >
              <div
                className="w-full text-xs text-left inv_table rounded-b !bg-white"
                id={`tableScroll`}
              >
                <div
                  className={`border-b text-xs text-gray-400 flex  ${
                    event?.isOpen && "tableHead"
                  } 
                  ${event?.isOpenMarket ? "mkt-open" : ""}
                  tableHead overflow-auto scrollbar-none bg-white custom-head-below`}
                  id={`loadHead${event?.id}`}
                  ref={headerRef}
                  onScroll={() => {
                    let scrollLeftf = Number(
                      document.getElementById(`loadHead${event?.id}`)
                        ?.scrollLeft
                    );
                    if (_.size(event?.eventRecords) === 1) {
                      document
                        .getElementById(`${event?.id}lazyLoad`)
                        ?.scrollTo({
                          left: scrollLeftf,
                        });
                    } else {
                    }
                  }}
                >
                  {eventLoader || event?.eventLoader ? (
                    <div className="px-1.5 py-3 font-medium whitespace-nowrap flex flex-wrap w-[100%] gap-y-2.5">
                      <div
                        className="bg-gray-100 rounded mr-1  w-full shimmer-effect"
                        style={{ height: "30px" }}
                      >
                        <div className="flex gap-2 rounded"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {isGodAdmin && (
                        <div
                          style={{ width: "100px" }}
                          className={clsx(
                            "flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] text-center !sticky left-0 p-0 z-10 no-scroll checkbox_td",
                            isGodAdmin &&
                              _.size(layout?.isTableEditable) > 0 &&
                              "pointer-events-none",
                            layout?.selectAllConfirmation &&
                              "pointer-events-none",
                            layout?.selectAllLoader && "pointer-events-none"
                          )}
                          id="checkbox_shad"
                        >
                          <div className="lock-icon relative w-10 h-full border-r">
                            <label className="cursor-pointer">
                              <input
                                type="checkbox"
                                className="invisible absolute"
                                readOnly
                                onClick={(e: any) => {
                                  handleOnUnLockAll(e, event?.id);
                                }}
                              />
                              {isAllb?.length > 0 ? (
                                <IKTSVG
                                  path={
                                    isAllb?.[eIndex] === false
                                      ? LockIcon
                                      : LockIconOpen
                                  }
                                  svgClassName="w-[.9375rem] h-[.9375rem]"
                                />
                              ) : (
                                <IKTSVG path={LockIcon} />
                              )}
                            </label>
                          </div>
                        </div>
                      )}
                      <div
                        style={{ width: "100px" }}
                        className={clsx(
                          "flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] text-center !sticky left-0 p-0 z-10 no-scroll checkbox_td",
                          isGodAdmin && "!left-[2.5rem]",
                          isAllSumOf[eIndex] !== true &&
                            isGodAdmin &&
                            "pointer-events-none"
                        )}
                        id="checkbox_shad"
                      >
                        <label
                          htmlFor={`all-${eIndex}`}
                          className={clsx(
                            "min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] absolute top-0 cursor-pointer p-1 border-r w-10 flex items-center justify-center",
                            (disabledAll || layout?.selectAllConfirmation) &&
                              "pointer-events-none !border-gray-300",
                            layout?.selectAllLoader && "pointer-events-none"
                          )}
                        ></label>
                        <input
                          type="checkbox"
                          id={`all-${eIndex}`}
                          key={`all-${eIndex}`}
                          checked={headerCheckboxValue}
                          disabled={
                            layout?.selectAllLoader ||
                            layout?.isBulkActionLoader ||
                            bulkUploadCount(list) > 0
                              ? true
                              : false
                          }
                          className={`${
                            (disabledAll || layout?.selectAllConfirmation) &&
                            "pointer-events-none opacity-50"
                          } w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0`}
                          onChange={(data) => handleSelectAll(data, eIndex)}
                        />
                      </div>

                      <div
                        className={clsx(
                          "flex",
                          (disabledAll || bulkUploadCount(list) > 0) &&
                            "pointer-events-none",
                          layout.selectAllLoader && "!pointer-events-none"
                        )}
                      >
                        <HeaderCellWrapper
                          event={event}
                          handleSortAction={handleSortAction}
                          SortingCurrentID={SortingCurrentID}
                          setSortingCurrentID={setSortingCurrentID}
                        />
                      </div>

                      <div
                        className={clsx(
                          "md:sticky relative right-0 p-0 border-l bg-white ml-auto",
                          event?.eventRecords?.length > 15
                            ? "min-w-[15rem] max-md:!min-w-[16.875rem]"
                            : "min-w-[15rem] max-md:!min-w-[16.875rem]",
                          layout.proceedPriceToggle &&
                            !isMobile &&
                            "!min-w-[23.25rem] !max-w-[23.25rem]"
                        )}
                      >
                        <div
                          className={`py-2 px-4 flex justify-end h-[2.5rem] left-shad ${
                            layout.proceedPriceToggle &&
                            !isMobile &&
                            "justify-between pl-2.5 !p-0"
                          }`}
                          id="left-shad-list"
                        >
                          {layout.proceedPriceToggle && !isMobile && (
                            <div
                              className={clsx(
                                "proceed_price_title flex items-center border-r px-2.5 pl-1.5 min-w-[8.1875rem]",
                                (disabledAll || bulkUploadCount(list) > 0) &&
                                  "pointer-events-none",
                                layout.selectAllLoader && "!pointer-events-none"
                              )}
                            >
                              <EventHeaderCell
                                handleSortAction={handleSortAction}
                                title="Proceed price"
                                arrow={true}
                                order={layout.orderState}
                                event={event}
                                postKey="sell_price"
                                minWidth={`5.625rem`}
                                setSortingCurrentID={setSortingCurrentID}
                                SortingCurrentID={SortingCurrentID}
                              />
                            </div>
                          )}
                          <div
                            className={`flex items-center justify-end ml-auto ${
                              layout.proceedPriceToggle &&
                              !isMobile &&
                              "px-4 py-2"
                            }`}
                          >
                            <div
                              className={`arrow_buttons md:shadow-op1 rounded `}
                            >
                              <button
                                className="group rounded-l  hover: transition px-[.5625rem] py-2 max-md:opacity-0 max-md:pointer-events-none"
                                id="leftArrow"
                                type="button"
                                onClick={(e: any) => scroll(-120, event?.id)}
                              >
                                <IKTSVG
                                  className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                  path={AngleLeft}
                                  svgClassName="w-1.5 h-[.5625rem]"
                                />
                              </button>
                              <button
                                className="group rounded-r  transition px-[.5625rem] py-2 max-md:opacity-0 max-md:pointer-events-none"
                                id="rightArrow"
                                type="button"
                                onClick={(e: any) => scroll(120, event?.id)}
                              >
                                <IKTSVG
                                  className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                  path={AngleRight}
                                  svgClassName="w-1.5 h-[.5625rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    "relative flex w-full bg-white items-center justify-center font-medium text-sm-13 overflow-hidden z-[1] selectAllStripe",
                    "after:contents[''] after:absolute after:left-0 h-0 after:top-0 after:w-full after:h-full after:bg-indigo-500 after:bg-opacity-[7%]",
                    layout?.selectAllLoader && "pointer-events-none",
                    _.size(event?.requireSelectAll) > 0 && "active shadow-op2"
                  )}
                  id={`selectAllStripe`}
                >
                  <div className="flex items-center justify-center py-2 z-[1]">
                    <IKTSVG
                      svgClassName="w-[.75rem] h-[.5625rem]"
                      path={ListingLinesSVG}
                    ></IKTSVG>
                    <p className="ml-[.375rem] text-violet-800 mr-2">
                      {selectedCounts?.[eIndex]} selected
                    </p>
                    <button
                      type="button"
                      className="bg-violet-500 text-white rounded pt-[.2188rem] pb-[.2813rem] px-[.625rem] hover:bg-indigo-500 text-sm13"
                      onClick={(e: any) =>
                        handleSelectLoadAll(e, event, eIndex)
                      }
                    >
                      Select all {event?.requireSelectAll?.total_listings}{" "}
                      listings
                    </button>
                  </div>
                </div>

                <Virtuoso
                  data={event?.eventRecords}
                  id={event?.id + "lazyLoad"}
                  increaseViewportBy={20}
                  endReached={async () => {
                    if (
                      event?.isOpen &&
                      event?.meta?.last_page > event?.meta?.current_page &&
                      !isLoading
                    ) {
                      setIsLoading(true);
                      const res = await loadMoreListing(event);
                      if (res) {
                        setIsLoading(false);
                      }
                    }
                  }}
                  overscan={200}
                  ref={virtuosoRef}
                  onScroll={debouncedHandleScroll}
                  onWheel={debouncedHandleScroll}
                  totalCount={event?.eventRecords?.length}
                  useWindowScroll
                  components={{
                    Footer: () => {
                      return (
                        <div
                          className={` ${
                            (event?.eventRecords?.length ===
                              event?.total_listings ||
                              event?.meta?.last_page ===
                                event?.meta?.current_page) &&
                            event?.meta?.current_page > 1
                              ? "show"
                              : "hidden"
                          }  text-center text-sm13 py-3 px-2.5 bg-white sticky left-0 top-0 w-[calc(100vw-7.1875rem)]`}
                        >
                          No more items to show
                        </div>
                      );
                    },
                  }}
                  className="tableBody rounded-b !scrollbar-none"
                  itemContent={(rIndex: any, eventRecord: any) => {
                    const eventFieldViewWrapper = (
                      <EventFieldView
                        eventRecord={eventRecord}
                        list={list}
                        setList={setList}
                        eIndex={eIndex}
                        rIndex={rIndex}
                        event={event}
                        disabledAll={disabledAll}
                        forRowDisabled={forRowDisabled}
                        setCurrentRecordId={updateIdOnHover}
                        isTableEditable={isTableEditable}
                        firstSelectedIndex={firstSelectedIndex}
                        getColumnItems={getColumnItems}
                        Messages={Messages}
                        className={`2.800rem`}
                      />
                    );
                    if (isMobile) {
                      return (
                        <EventFieldViewMobile
                          list={list}
                          setList={setList}
                          eIndex={eIndex}
                          rIndex={rIndex}
                          event={event}
                          disabledAll={disabledAll}
                          forRowDisabled={forRowDisabled}
                          setCurrentRecordId={updateIdOnHover}
                          isTableEditable={isTableEditable}
                          firstSelectedIndex={firstSelectedIndex}
                          getColumnItems={getColumnItems}
                          Messages={Messages}
                          className={`2.800rem`}
                          tempEventRecord={eventRecord}
                          updateVisibility={updateVisibility}
                          setIsOpenListingQualityPopup={
                            setIsOpenListingQualityPopup
                          }
                          HaxPublishGray={HaxPublishGray}
                          unpublish={unpublish}
                          handleListingAction={handleListingAction}
                          setEditView={setEditView}
                          handleEticket={handleEticket}
                          setIsOpenTicketPopUp={setIsOpenTicketPopUp}
                          display={display}
                          tableRefSide={tableRefSide}
                          setEditData={setEditData}
                          editData={editData}
                        />
                      );
                    } else {
                      // for desktop
                      if (
                        layout?.currentIndex?.eIndex === event?.id &&
                        layout?.currentIndex?.rIndex === rIndex &&
                        eventRecord?.isInputChange &&
                        layout.isTableEditable?.length === 0
                          ? true
                          : eventRecord?.updateMode ||
                            (currentRecordId?.recordIndex === rIndex &&
                              currentRecordId?.eventIndex === eIndex) ||
                            (layout.isTableEditable?.length > 0 &&
                              // eventRecord?.selected === true
                              eventRecord?.id === firstSelectedIndex?.id)
                      ) {
                        return (
                          <EventField
                            event={event}
                            eIndex={eIndex}
                            tempEventRecord={eventRecord}
                            list={list}
                            setList={setList}
                            rIndex={rIndex}
                            firstSelectedIndex={firstSelectedIndex}
                            matchIndexView={matchIndexView}
                            getColumnItems={getColumnItems}
                            subTicketType={subTicketType}
                            handleEticket={handleEticket}
                            setIsOpenTicketPopUp={setIsOpenTicketPopUp}
                            tableRefSide={tableRefSide}
                            setEditData={setEditData}
                            handleListingAction={handleListingAction}
                            editData={editData}
                            updateVisibility={updateVisibility}
                            setIsOpenListingQualityPopup={
                              setIsOpenListingQualityPopup
                            }
                          />
                        );
                      } else {
                        if (
                          layout.isTableEditable?.length > 0 &&
                          eventRecord?.selected
                        ) {
                          // this is for Bulk edit only
                          return (
                            <EventFieldViewBulkEdit
                              eventRecord={eventRecord}
                              list={list}
                              setList={setList}
                              eIndex={eIndex}
                              rIndex={rIndex}
                              event={event}
                              disabledAll={disabledAll}
                              forRowDisabled={forRowDisabled}
                              setCurrentRecordId={updateIdOnHover}
                              isTableEditable={isTableEditable}
                              firstSelectedIndex={firstSelectedIndex}
                              getColumnItems={getColumnItems}
                              Messages={Messages}
                              className={`2.800rem`}
                              // isGodadmin={isGodAdmin}
                            />
                          );
                        } else {
                          return eventFieldViewWrapper;
                        }
                      }
                    }
                  }}
                />
              </div>

              {(event?.meta_current_page > 1 ||
                layout?.updateAllLoader === false) && (
                <div
                  id={`child2`}
                  className={`order-[10000000] m-1 z-99999999
                 ${
                   event?.eventRecords?.length ===
                     Number(event?.total_listings) ||
                   !event?.eventRecords ||
                   event?.meta?.last_page === event?.meta?.current_page
                     ? "!hidden"
                     : ""
                 }
                 `}
                >
                  <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem] !overflow-hidden"></div>
                  <div className="bg-gray-100 rounded mr-1 mt-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default EventFields3;
